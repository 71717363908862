import { t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { NoddiIcon, colors } from 'noddi-ui';
import styled from 'styled-components';

const HasPayedContainer = styled.div`
  background-color: ${colors.primary.white};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  margin-top: 24px;
`;

export default function PaymentInfo({ paymentMethod }: { orderId: number; paymentMethod: string }) {
  return (
    <HasPayedContainer>
      <div style={{ alignItems: 'center', display: 'flex' }}>
        <NoddiIcon name='Check' color={colors.primary.orange} className='mr-1' />

        <Typography ml={2} fontWeight={500}>
          {paymentMethod.toLowerCase().includes('invoice')
            ? t`Thanks! You will receive an invoice shortly`
            : t`Payment completed`}
        </Typography>
      </div>
    </HasPayedContainer>
  );
}
