import { Trans } from '@lingui/macro';
import { NoddiButton } from 'noddi-ui';
import { PropsWithChildren, ReactNode, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BookingContainer, InnerContainer, TopContainer } from '../../../BookingFlow/BookingScreen';
import FixedBottomBar from '../../../BookingFlow/FixedBottomBar';
import PublicServiceAnnouncement from '../../../BookingFlow/PublicServiceAnnoncement';
import { useScrollToTop } from '../../../BookingFlow/hooks/useScrollToTop';
import { useExpandBookingNavigateOnInvalidStep } from './hooks/useExpandBookingNavigateOnInvalidStep';

interface ScreenProps {
  title: string;
  description?: string;
  onNextClicked?: () => void;
  disableNextButton?: boolean;
  hideNextButton?: boolean;
  hideShoppingCart?: boolean;
  CustomBottomBar?: ReactNode;
}

const ExpandBookingScreen = ({
  title,
  children,
  description,
  onNextClicked,
  disableNextButton,
  CustomBottomBar,
  hideNextButton,
  hideShoppingCart
}: PropsWithChildren<ScreenProps>) => {
  const { navigateOnInvalidStep } = useExpandBookingNavigateOnInvalidStep();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    navigateOnInvalidStep();
  }, [pathname]);

  useScrollToTop();

  return (
    <TopContainer>
      <InnerContainer>
        <BookingContainer>
          <div className='mx-auto mb-17 w-full max-w-2xl flex-col px-6 lg:px-0'>
            <PublicServiceAnnouncement />
            <div className='flex-col'>
              <div className='mb-5 flex flex-col gap-2 sm:mb-10'>
                <div className='flex flex-col'>
                  <NoddiButton
                    startIcon='ArrowLeft'
                    onClick={() => {
                      navigate(-1);
                    }}
                    variant='ghost'
                    className='-ml-3.5'
                  >
                    <Trans>Back</Trans>
                  </NoddiButton>
                  <h1 className='text-7 text-primary-darkPurple sm:text-13'>{title}</h1>
                </div>
                {description && <p className='ml-1 text-secondary-black lg:ml-2'>{description}</p>}
              </div>
              {children}
            </div>
          </div>
        </BookingContainer>
      </InnerContainer>
      {CustomBottomBar ?? (
        <FixedBottomBar
          hideNextButton={hideNextButton}
          disableNextButton={disableNextButton}
          hideShoppingCart={hideShoppingCart}
          onNextClicked={onNextClicked}
        />
      )}
    </TopContainer>
  );
};

export default ExpandBookingScreen;
