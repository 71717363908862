import { AxiosError } from 'axios';
import { errorCodes as errorCodesWithoutTrans } from '../../../../errorCodes';
import { NoddiFeedbackBox } from '../../../../molecules';
import { useCaptureException } from '../utils';

export type NoddiAsyncError = AxiosError<{
  type: string;
  errors: { code: string; detail: string; attr: string }[];
}>;

export interface ApiErrorMessageProps {
  error: NoddiAsyncError | Array<NoddiAsyncError | null>;
  isSmallVersion?: boolean;
  errorCodes?: Record<string, string> & { genericErrorMessage: string };
}

export const mapErrorCodeToUiMessage = ({
  error,
  errorCodes
}: {
  error: NoddiAsyncError;
  errorCodes?: Record<string, string> & { genericErrorMessage: string };
}) => {
  const errors = error.response?.data.errors ?? [];

  const errorCodeTexts = errorCodes ?? errorCodesWithoutTrans;

  if (!errors) {
    return errorCodeTexts.genericErrorMessage;
  }
  if (errors.length === 1) {
    const code = errors[0]?.code;

    return errorCodeTexts[code as keyof typeof errorCodeTexts] ?? errorCodeTexts.genericErrorMessage;
  } else if (errors.length > 1) {
    //writes reducer that iterates through errors and returns a string
    const message = errors.reduce((prev, curr, index) => {
      const code = curr?.code ?? '';

      const errorCode = errorCodeTexts[code as keyof typeof errorCodeTexts] ?? errorCodeTexts.genericErrorMessage;
      if (index === errors.length - 1) {
        prev += errorCode;
      } else {
        if (errorCode) {
          prev += `${errorCode}, `;
        }
      }

      return prev;
    }, '');
    return message;
  }
  return errorCodeTexts.genericErrorMessage;
};

export function ApiErrorMessage({ error, isSmallVersion, errorCodes }: ApiErrorMessageProps) {
  const errorToRender = Array.isArray(error) ? error.find((e) => e !== null) : error;
  useCaptureException(errorToRender, 'ApiErrorMessage');

  if (!errorToRender) {
    return null;
  }

  return (
    <div className='mt-2'>
      <NoddiFeedbackBox
        variant='error'
        description={mapErrorCodeToUiMessage({
          error: errorToRender,
          errorCodes: errorCodes ?? errorCodesWithoutTrans
        })}
        size={isSmallVersion ? 'small' : 'default'}
      />
    </div>
  );
}

interface ErrorMessageProps {
  message: string;
}

export function ErrorMessage({ message }: ErrorMessageProps) {
  useCaptureException(message, 'ErrorMessage');

  return (
    <div className='mt-2'>
      <NoddiFeedbackBox variant='error' description={message} />
    </div>
  );
}
