import { TireHotelContract } from 'noddi-async/src/types';
import { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';

import { TireStorageBookingSteps } from '../tireStorageBookingSteps';

interface TireHotelBookingType {
  selectedTireHotelContracts: TireHotelContract[];
  dialogOpen: boolean;
  currentStep: TireStorageBookingSteps;
  setSelectedTireHotelContracts: Dispatch<SetStateAction<TireHotelContract[]>>;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
  setCurrentStep: Dispatch<SetStateAction<TireStorageBookingSteps>>;
  resetTireHotelData: () => void;
}

const TireHotelContext = createContext<TireHotelBookingType>({} as TireHotelBookingType);

export const TireHotelProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedTireHotelContracts, setSelectedTireHotelContracts] = useState<TireHotelContract[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(TireStorageBookingSteps.address);

  const resetTireHotelData = () => {
    setSelectedTireHotelContracts([]);
    setDialogOpen(false);
    setCurrentStep(TireStorageBookingSteps.address);
  };

  return (
    <TireHotelContext.Provider
      value={{
        selectedTireHotelContracts,
        dialogOpen,
        currentStep,
        setCurrentStep,
        setSelectedTireHotelContracts,
        setDialogOpen,
        resetTireHotelData
      }}
    >
      {children}
    </TireHotelContext.Provider>
  );
};

export function useTireHotelContext() {
  const context = useContext(TireHotelContext);
  if (context === undefined) {
    throw new Error('useTireStorageContext must be used within a TireStorageProvider');
  }
  return context;
}
