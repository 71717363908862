import { Typography } from '@mui/material';
import { NoddiCollapseCard, colors } from 'noddi-ui';

const info = [
  'Du trenger ikke være hjemme når vi kommer. Om du har innvendig vask behøver vi tilgang til å låse opp bilen. Om du legger ut nøkkelen er det på eget ansvar.',
  'Du får sms når vi er på vei til deg, typisk 10 til 30 minutter før ankomst. Denne inneholder også mobilnummer til teknikeren din om du behøver å komme i kontakt.',
  'Betaling skjer digitalt etter avtalen på denne siden, med kredittkort eller Vipps.',
  'Du kan gjøre endringer på avtalen kostnadsfritt frem til klokken 18:00 dagen før. Etter dette kan det komme et endringsgebyr.',
  'Dersom du har bestilt dekkskift og ikke har lagt til bærehjelp må sette dekkene mindre enn 10 meter unna bilen, på bakkeplan. Vi kan fint hjelpe deg å bære de, men det vil da komme et lite tilleggsgebyr.'
];

export default function RelevantQuestionsForBooking() {
  return (
    <NoddiCollapseCard
      backgroundColor={colors.primary.white}
      header={<Typography variant='h6'>Informasjon om din avtale med Noddi</Typography>}
      collapseBody={
        <>
          {info.map((item, index) => (
            <Typography key={index} variant='body1' style={{ marginTop: 4 }}>
              <b style={{ color: colors.primary.orange }}>{index + 1}.&nbsp;</b>
              {item}
            </Typography>
          ))}
        </>
      }
    />
  );
}
