import { Trans, t } from '@lingui/macro';
import { Form, Formik } from 'formik';
import { URLKeys, noddiAsync } from 'noddi-async';
import { invalidateQueryKey } from 'noddi-async/src/utils';
import { useAuthContext } from 'noddi-provider';
import { LoadingScreen, NoddiButton, NoddiFormTextInput, SectionSpacer, useIsMobile } from 'noddi-ui';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import routes from '../../../appRoutes';
import { ApiErrorMessageWithTrans } from '../../../components/ApiErrorMessageWithTrans';
import { BenefitsLogin } from '../../../components/BenefitsLogin';
import ContentWrapper from '../../../components/Layouts/ContentWrapper';
import { getImageSrc } from '../../../utils/resolveCompanyLogo';
import { bookingCategoryLabelToEnum } from '../../BookingFlow/Steps/SalesItems/utils';
import { UserAlreadyAMember } from '../AlreadyMember';
import { HighlightedSalesItem } from '../[slug]/HighLightedSalesItem';
import { membershipDetailSchema } from './validators';

type FormInput = {
  membershipNumber: string;
  dateOfBirth: string;
};

const FormErrorMessage = styled.p`
  color: #f44336;
  margin: 0;
  padding: 0;
`;

const getDateOfBirthDto = (dateOfBirth: string) => {
  if (dateOfBirth === '' || dateOfBirth.length < 8) {
    return null;
  }
  const dateOfBirthStripped = dateOfBirth.replace(/[./-]/g, '');

  const year = dateOfBirthStripped.slice(-4);
  const month = dateOfBirthStripped.slice(2, 4);
  const day = dateOfBirthStripped.slice(0, 2);
  return `${year}-${month}-${day}`;
};

const buildBodyParams = (data: FormInput, userGroupId: number, phoneNumber: string) => {
  const memberNumber = data.membershipNumber;
  const dateOfBirth = getDateOfBirthDto(data.dateOfBirth);

  if (memberNumber) {
    return {
      userGroupId,
      memberNumber,
      phoneNumber: null,
      dateOfBirth: null
    };
  }

  if (dateOfBirth) {
    return {
      userGroupId,
      memberNumber: null,
      phoneNumber,
      dateOfBirth
    };
  }

  return null;
};

const MembershipNumberForm = () => {
  const { userData, currentUserGroupId } = useAuthContext();

  const navigate = useNavigate();
  const [customErrorMessage, setCustomErrorMessage] = useState<string | null>(null);
  const [membershipLogin, setMembershipLogin] = useState<boolean>(true);

  const onSubmit = async (data: FormInput) => {
    setCustomErrorMessage(null);

    const phoneNumber = userData?.user.phoneNumber;

    if (!currentUserGroupId) {
      return;
    }

    if (!phoneNumber) {
      setCustomErrorMessage(
        t`Your profile does not have a registered phone number. Contact customer service and we will fix it for you`
      );
      return;
    }

    try {
      const bodyParams = buildBodyParams(data, currentUserGroupId, phoneNumber);
      // we should always have bodyParams, as we are disabling the submit button if the form is not valid
      if (bodyParams) {
        await mutateAsync(bodyParams);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const initialValues = {
    membershipNumber: '',
    dateOfBirth: ''
  };

  const {
    mutateAsync,
    error: apiError,
    isPending
  } = noddiAsync.usePost({
    type: URLKeys.postNafMembership,
    queryConfig: {
      onSuccess: async ({ data }) => {
        const { membershipAdded } = data;

        if (membershipAdded) {
          invalidateQueryKey({ urlKey: URLKeys.getUser });

          navigate(routes.confirmation.getPath({ params: 'type=membership_added&membershipName=NAF' }));
        } else {
          setCustomErrorMessage(t`Something went wrong, please try again later`);
        }
      }
    }
  });

  const dateOfBirthTitle = t`Date of birth`;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={membershipDetailSchema(membershipLogin)}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <div style={{ display: membershipLogin ? 'block' : 'none' }}>
            <SectionSpacer fullWidth>
              <NoddiFormTextInput name='membershipNumber' label={t`NAF membership number`} placeholder='1234...' />
            </SectionSpacer>
            <p>
              <Trans>Don't remember your NAF membership number?</Trans>
            </p>
            <NoddiButton
              variant='link'
              className='pl-0'
              endIcon='ArrowRight'
              onClick={() => setMembershipLogin(!membershipLogin)}
            >
              <Trans>Register using date of birth instead</Trans>
            </NoddiButton>
          </div>

          <div style={{ display: membershipLogin ? 'none' : 'block' }}>
            <SectionSpacer fullWidth>
              <NoddiFormTextInput name='dateOfBirth' label={dateOfBirthTitle} placeholder={t`dd.mm.yyyy`} />
            </SectionSpacer>

            <NoddiButton
              variant='link'
              className='pl-0'
              endIcon='ArrowRight'
              onClick={() => setMembershipLogin(!membershipLogin)}
            >
              <Trans>Register with your membership number</Trans>
            </NoddiButton>
          </div>
          {apiError && <ApiErrorMessageWithTrans error={apiError} />}
          {customErrorMessage && <FormErrorMessage>{customErrorMessage}</FormErrorMessage>}

          <NoddiButton
            style={{ marginTop: '24px' }}
            type='submit'
            fullWidth
            disabled={!isValid || isSubmitting || isPending}
            loading={isSubmitting || isPending}
          >
            <Trans>Confirm membership</Trans>
          </NoddiButton>
        </Form>
      )}
    </Formik>
  );
};

const MembershipNAF = () => {
  const isMobile = useIsMobile();
  const imageSize = isMobile ? 48 : 64;
  const { isLoggedIn, getCurrentUserGroupId } = useAuthContext();
  const userGroupId = getCurrentUserGroupId();

  const { data: isMemberData, isLoading } = noddiAsync.useGet({
    type: URLKeys.getIsNAFMember,
    input: { userGroupId: userGroupId as number },
    queryConfig: {
      enabled: !!userGroupId
    }
  });

  if (isLoading) {
    return <LoadingScreen />;
  }

  const isMember = isMemberData?.isMember || false;
  const safeSlug = 'naf';

  return (
    <ContentWrapper>
      <div className='flex flex-col gap-8 text-primary-darkPurple'>
        <div className='flex items-center justify-between'>
          <h1 className='font-bold text-8 text-primary-darkPurple md:text-11'>
            <Trans>NAF benefits</Trans>
          </h1>
          {getImageSrc(safeSlug) && (
            <div
              className='mr-2'
              style={{
                height: imageSize,
                width: imageSize
              }}
            >
              <img src={getImageSrc(safeSlug)} />
            </div>
          )}
        </div>
        {isLoggedIn && !isMember && <MembershipNumberForm />}
        {!isLoggedIn && <BenefitsLogin />}

        {isLoggedIn && isMember ? (
          <UserAlreadyAMember />
        ) : (
          <p className='opacity-80'>
            <Trans>
              NAF cares about the safety of all its members and therefore it is important that you have the right tires
              on at the right time. But why drive to the workshop, when it can come to you? Noddi is car made easy!
            </Trans>
          </p>
        )}

        <HighlightedSalesItem
          serviceName={t`Get 10 % off on every service`}
          type={bookingCategoryLabelToEnum('wheel_services')}
          hidePopular
        />
      </div>
    </ContentWrapper>
  );
};
export default MembershipNAF;
