import { Trans, t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { GeneralTireInfo, LoadingScreen, TireMeasurement, getCarDisplayName, resolveWheelMeasurement } from 'noddi-ui';
import { useNavigate, useParams } from 'react-router-dom';

import routes from '../../appRoutes';
import getCommonTranslations from '../../commonTranslations';
import ContentWrapper from '../../components/Layouts/ContentWrapper';

const WheelMeasureMentPage = () => {
  const { currentUserGroupId: userGroupId } = useAuthContext();
  const navigate = useNavigate();
  const { carId } = useParams();

  if (!carId) {
    navigate(routes.homepage.getPath());
    return null;
  }

  const { data: car, isPending } = noddiAsync.useGet({
    type: URLKeys.getUserGroupCar,
    input: { userGroupId, carId },
    queryConfig: { enabled: !!userGroupId, staleTime: Infinity }
  });

  if (isPending) {
    return <LoadingScreen />;
  }
  if (!car) {
    return (
      <ContentWrapper title={t`Wheel measurements`}>
        <Typography variant='h4'>
          <Trans>Wheel measurements</Trans>
        </Typography>
        <div style={{ marginTop: '10px' }}>
          <Stack my={3}>
            <Typography textAlign='center' variant='subtitle1'>
              <Trans>Could not find any data for this car</Trans>
            </Typography>
          </Stack>
        </div>
      </ContentWrapper>
    );
  }

  const { summer, winter } = resolveWheelMeasurement(car);
  const title = getCarDisplayName(car);

  return (
    <ContentWrapper title={title}>
      <div className='flex flex-col gap-8'>
        {winter && (
          <div className='flex flex-col gap-4 '>
            <h6 className='font-bold text-5 text-primary-darkPurple md:text-7'>
              {getCommonTranslations().wheelMeasurement.winterTires}
            </h6>

            <TireMeasurement
              tireType='winter'
              wheels={winter.wheels}
              translations={getCommonTranslations().wheelMeasurement}
            />
          </div>
        )}
        {summer && (
          <div className='flex flex-col gap-4 '>
            <h6 className='font-bold text-5 text-primary-darkPurple md:text-7'>
              {getCommonTranslations().wheelMeasurement.summerTires}
            </h6>
            <TireMeasurement
              tireType='summer'
              wheels={summer.wheels}
              translations={getCommonTranslations().wheelMeasurement}
            />
          </div>
        )}
        {!summer && !winter && (
          <Typography>
            <Trans>No measurements found. Contact hei@noddi.no if this is wrong</Trans>
          </Typography>
        )}
      </div>

      <GeneralTireInfo
        translations={{
          summerTires: getCommonTranslations().wheelMeasurement.summerTires,
          summerTiresMinimumNotice: getCommonTranslations().summerTiresMinimumNotice,
          standardsForWinterAndSummerTires: getCommonTranslations().standardsForWinterAndSummerTires,
          winterTires: getCommonTranslations().wheelMeasurement.winterTires,
          winterTiresMinimumNotice: getCommonTranslations().winterTiresMinimumNotice
        }}
      />
    </ContentWrapper>
  );
};

export default WheelMeasureMentPage;
