import { Typography } from '@mui/material';
import { Stack } from '@mui/system';

import { NoddiIcon } from '../../../../atoms';
import { NoddiCollapseCard } from '../../../Elements/Cards/NoddiCollapseCard';

interface GeneralTireInfoProps {
  translations: {
    standardsForWinterAndSummerTires: string;
    winterTires: string;
    summerTires: string;
    winterTiresMinimumNotice: string;
    summerTiresMinimumNotice: string;
  };
}

const GeneralTireInfo = ({ translations }: GeneralTireInfoProps) => {
  return (
    <NoddiCollapseCard
      sx={{ marginTop: 2 }}
      header={
        <p className='flex items-center gap-3 text-5 font-semibold'>
          <div>
            <NoddiIcon name='Info' />
          </div>
          {translations.standardsForWinterAndSummerTires}
        </p>
      }
      collapseBody={
        <Stack marginTop={2}>
          <Typography variant='h6'>{translations.winterTires} </Typography>
          <Typography color='text.secondary' mt={1}>
            {translations.winterTiresMinimumNotice}
          </Typography>

          <Typography variant='h6' marginTop={3}>
            {translations.summerTires}
          </Typography>
          <Typography color='text.secondary' mt={1}>
            {translations.summerTiresMinimumNotice}
          </Typography>
        </Stack>
      }
    />
  );
};

export { GeneralTireInfo };
