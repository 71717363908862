import { Stack } from '@mui/system';
import { useAuthContext } from 'noddi-provider';
import { NoddiButton, NoddiDialog } from 'noddi-ui';
import { useState } from 'react';

import ChooseImpersonateUser from './ChooseSuperUser';

const ImpersonateUserButton = ({
  handleClearDataWhenImpersonating
}: {
  handleClearDataWhenImpersonating?: () => void;
}) => {
  const { isSuperUser } = useAuthContext();
  const [isSuperUserModalOpen, setIsSuperUserModalOpen] = useState(false);

  return isSuperUser ? (
    <>
      <Stack padding={1}>
        <div className='mx-4'>
          <NoddiButton size='small' variant='secondary' onClick={() => setIsSuperUserModalOpen(true)} startIcon='User'>
            Impersonate user
          </NoddiButton>
        </div>
      </Stack>
      <NoddiDialog
        open={isSuperUserModalOpen}
        onClose={() => setIsSuperUserModalOpen(false)}
        title='Impersonate user'
        maxWidth='xl'
        contentProps={{
          sx: {
            height: {
              xs: 'unset',
              sm: '80vh'
            }
          }
        }}
      >
        <Stack justifyContent='space-between'>
          <Stack style={{ marginTop: 22, marginBottom: 22 }}>
            <p className='mt-4 text-secondary-black'>
              By choosing a user from this list, you will impersonate that user. This means that you will be able to see
              the application as that user, and perform actions on behalf of that user
            </p>
          </Stack>
          <ChooseImpersonateUser
            handleClose={() => setIsSuperUserModalOpen(false)}
            handleClearDataWhenImpersonating={handleClearDataWhenImpersonating}
          />
        </Stack>
      </NoddiDialog>
    </>
  ) : null;
};

export default ImpersonateUserButton;
