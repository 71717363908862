import { Trans } from '@lingui/macro';
import { AnimatePresence, motion } from 'framer-motion';
import { NoddiButton, useOnClickOutside } from 'noddi-ui';
import { Fragment, useRef, useState } from 'react';

import { LicensePlateCountryOption, getLicensePlateCountryOptions } from './LicensePlateCountryOptions';

interface LicensePlateCountrySelectorProps {
  setLicensePlateCountry: (country: LicensePlateCountryOption) => void;
  licensePlateCountry?: LicensePlateCountryOption;
}

const LicensePlateCountrySelector = ({
  setLicensePlateCountry,
  licensePlateCountry
}: LicensePlateCountrySelectorProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const options = getLicensePlateCountryOptions();

  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  return (
    <div ref={dropdownRef} className='w-fit'>
      <NoddiButton variant='link' startIcon='Globe' className='pl-0' onClick={() => setIsOpen(!isOpen)}>
        <Trans>Search for car in another country? </Trans>
      </NoddiButton>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.3 }}
            className='absolute z-[10000] mt-2 rounded-2xl bg-primary-white shadow-sm'
          >
            {options.map((option, idx) => (
              <Fragment key={option.value}>
                <div
                  className={`flex cursor-pointer p-4 ${option.value === licensePlateCountry?.value ? 'font-bold' : ''}`}
                  onClick={() => {
                    setLicensePlateCountry(option);
                    setIsOpen(false);
                  }}
                >
                  <span>
                    {option.flag} {option.label}
                  </span>
                </div>
                {idx !== options.length - 1 && <div className='mx-3 h-0.25 bg-systemColors-outlineStroke' />}
              </Fragment>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export { LicensePlateCountrySelector };
