import { AvailableBookingTimeWindow } from 'noddi-async/src/types';
import { DateFormats, format } from 'noddi-util';

const bookingTimeWindowToDisplayText = (timeWindow: AvailableBookingTimeWindow) => {
  return `${format(timeWindow.startPublic, DateFormats.FULL_MONTH_YEAR_WITHOUT_COMMA)}, ${format(
    timeWindow.startPublic,
    DateFormats.TIME
  )} - ${format(timeWindow.endPublic, DateFormats.TIME)}`;
};

export default bookingTimeWindowToDisplayText;
