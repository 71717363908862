export const commonTexts = {
  chooseFromYourAddresses: 'Choose from your addresses?',
  couldNotFindAddress:
    "We're sorry, but we couldn't find the address you entered. Please double-check the address for any typos or missing details and try again.",
  addressNotFound: 'No addresses found',
  tryAgainWithStreetNumber:
    "We're sorry, but we couldn't find the address you entered. Please double-check the address for any typos or missing details and try again.",
  chooseFromAnotherAddress: 'Choose from another address?',
  forbiddenAccess: 'Forbidden access',
  youDoNotHavePermission: 'You do not have permission to access this application',
  pageNotFoundHeading: 'Page Not Found',
  pageNotFoundMessage:
    ' The page you are looking for has either been moved or no longer exists. If we have linked incorrectly, we would be very happy if you send us a message.',
  goBack: 'Go Back',
  enterStartAddress: 'Enter start address',
  enterEndAddress: 'Enter end address',
  selectStartAddress: 'Select start address',
  selectEndAddress: 'Select end address',
  chooseFromExistingAddress: 'Choose from existing address?',
  chooseNewStartAddress: 'Choose new start address?',
  chooseNewEndAddress: 'Choose new end address?',
  email: 'Email',
  password: 'Password',
  logIn: 'Logg inn',
  accessDeniedLoginMessage:
    'Access denied. If you believe this is a mistake or require permission, please contact us at hei@noddi.no.',
  enterCode: 'Enter code',
  confirm: 'Confirm',
  capacityContributionAddressess: {
    enterStartAddress: 'Enter start address',
    enterEndAddress: 'Enter end address',
    selectStartAddress: 'Select start address',
    selectEndAddress: 'Select end address',
    chooseFromExistingAddress: 'Choose from existing address?',
    chooseNewStartAddress: 'Choose new start address?',
    chooseNewEndAddress: 'Choose new end address?'
  },
  addressTranslations: {
    chooseFromYourAddresses: 'Choose from your addresses?',
    couldNotFindAddress:
      "We're sorry, but we couldn't find the address you entered. Please double-check the address for any typos or missing details and try again.",
    addressNotFound: 'No addresses found',
    tryAgainWithStreetNumber:
      "We're sorry, but we couldn't find the address you entered. Please double-check the address for any typos or missing details and try again.",
    chooseFromAnotherAddress: 'Choose from another address?'
  },
  selectDate: 'Select date',
  myRouteOnly: 'My route only',
  youDontHaveWorkerId: "You don't have a worker id associated with your profile.",
  smsLoginTranslations: {
    enterNumberToReceiveVerificationCode: 'Enter number to receive a verification code',
    resend: 'Resend',
    sendCode: 'Send code',
    didntReceiveSms: "Didn't receive sms?",
    enterCode: 'Enter code',
    confirm: 'Confirm'
  },
  pickingListTranslations: {
    missingStorageArea: 'Missing storagearea',
    missingStorageLocation: 'Missing storagelocation',
    noWheelsetsFound: 'No wheelsets found',
    tryChoosingAnotherDateOrStorageFacility: 'Try choosing another date or storage facility',
    multipleRoutesFound: 'Multiple routes found',
    atLoadingDock: 'At loading dock',
    inTransit: 'In transi',
    refresh: 'Refresh'
  },
  registrationTranslations: {
    thisFieldIsRequired: 'This field is required',
    thisFieldMustBeAtLeast2CharactersLong: 'This field must be at least 2 characters long',
    mustProvideAValidEmailAddress: 'Must provide a valid email address',
    passwordsMustMatch: 'Passwords must match',
    thePasswordMustBeAtLeast8CharactersLong: 'The password must be at least 8 characters long',
    firstName: 'First name',
    surname: 'Surname',
    email: 'Email',
    password: 'Password',
    confirmPassword: 'Confirm password',
    createUser: 'Create user',
    userInformation: 'User information'
  },
  profileUpdatedSuccessfully: 'Profile updated successfully',
  firstName: 'First name',
  lastName: 'Last name',
  phoneNumber: 'Phone number',
  save: 'Save',
  required: 'Required',
  addressValidationTranslations: {
    nameIsRequired: 'Name is required',
    streetNameIsRequired: 'Street name is required',
    streetNumberIsRequired: 'Street number is required',
    zipCodeIsRequired: 'Zip code is required',
    cityIsRequired: 'City is required',
    countryIsRequired: 'Country is required',
    latitudeIsRequired: 'Latitude is required',
    longitudeIsRequired: 'Longitude is required',
    descriptionIsRequired: 'Description is required',
    placeTypeIsRequired: 'Place type is required'
  },
  cannotAddDateThatAlreadyHasExistingAvailability:
    'Cannot add date that already has existing availability. Contact your supervisor',
  dateAlreadyInListRemoveExistingDateFromList: 'Date already in list, remove existing date from list',
  listOfAddedDates: 'List of added dates',

  summer: 'Summer',
  winter: 'Winter',
  licenseArea: 'License area',
  fromDate: 'From date',
  date: 'Date',

  userGroup: 'User group',
  serviceTime: 'Service time',
  serviceArea: 'Service area',
  timeWindow: 'Time window',
  price: 'Price',
  status: 'Status',
  amount: 'Amount',
  discount: 'Discount',
  used: 'Used',
  activated: 'Activated',
  customerComments: 'Customer comments',
  adminComments: 'Admin comments',
  workerComments: 'Worker comments',

  canceled: 'Canceled',
  startsTooLate: 'Starts too late',
  cars: 'Cars',
  unableToComplete: 'Unable to complete',
  thisRouteIsNotValid: 'This route is not valid',
  break: 'break',
  booking: 'Booking',
  waitingTime: 'Waiting time',
  table: {
    rowsPerPage: 'Rows per page',
    of: 'of',
    selected: 'selected',
    clearFilters: 'Clear filters'
  },
  orderSummary: {
    total: 'Total',
    discount: 'Discount',
    VAT: 'VAT',
    yourOrder: 'Your order',
    vat: 'VAT',
    other: 'Other'
  },

  completed: 'Completed',
  cancelled: 'Cancelled',
  assigned: 'Assigned',
  confirmed: 'Confirmed',
  draft: 'Draft',

  yourOrder: 'Your order',
  discounts: 'Discounts',
  other: 'Other',
  total: 'Total',
  vat: 'VAT',
  seeBooking: 'See booking',
  summary: 'Summary',
  successFullyCreated: 'Successfully created!',
  successFullyUpdated: 'Successfully updated!',
  unknown: 'Unknown'
};
