import { Trans, t } from '@lingui/macro';
import { OutlinedInput } from '@mui/material';
import { NoddiBasicCard } from 'noddi-ui';

type CommentBoxProps = {
  handleChange: (s: string) => void;
  comment?: string | null;
};

const CommentBox = ({ handleChange, comment }: CommentBoxProps) => {
  return (
    <NoddiBasicCard className='flex flex-col gap-3'>
      <p className='text-5'>
        <Trans>Any notes to technician?</Trans>
      </p>

      <OutlinedInput
        rows={2}
        sx={{ paddingY: 1.25, paddingX: 2, borderRadius: '8px' }}
        onChange={(e) => handleChange(e.target.value)}
        value={comment ?? ''}
        title='Delivery Code'
        placeholder={t`Optional note if any extra information is needed, like where to find tires, keys etc.`}
        multiline
      />
    </NoddiBasicCard>
  );
};

export default CommentBox;
