import { Alert, Stack } from '@mui/material';
import { useAuthContext } from 'noddi-provider';
import { colors, useIsMobile } from 'noddi-ui';

import useCleanupBooking from '../hooks/useCleanupBooking';

export default function ImpersonatingAlert({ style }: { style?: React.CSSProperties }) {
  const { impersonatedUser, clearImpersonatedUser } = useAuthContext();
  const { cleanupBookingData } = useCleanupBooking();

  const isMobile = useIsMobile();

  const handleStopImpersonating = () => {
    clearImpersonatedUser();
    cleanupBookingData();
  };

  return (
    <Alert
      severity='warning'
      sx={{
        backgroundColor: colors.primary.orange,
        color: 'white',
        height: 'fit-content',
        width: '100%',
        borderRadius: 0,
        '& .MuiAlert-icon': {
          color: 'white'
        },
        ...style
      }}
    >
      <Stack direction={isMobile ? 'column' : 'row'} justifyContent='space-between' width={isMobile ? '80%' : '100%'}>
        <div>
          You are impersonating as {impersonatedUser?.user.firstName} {impersonatedUser?.user.lastName} (
          {impersonatedUser?.user.phoneNumber})
        </div>
        <div
          onClick={handleStopImpersonating}
          style={{
            marginLeft: isMobile ? 0 : 20,
            marginTop: isMobile ? 10 : undefined,
            textDecoration: 'underline',
            cursor: 'pointer'
          }}
        >
          Stop impersonating
        </div>
      </Stack>
    </Alert>
  );
}
