import { NoddiIcon, colors } from 'noddi-ui';

import { getServiceCategoryTranslations } from '../../utils/translation';

type UnavailableServiceCategoryProps = {
  serviceCategoryName: string;
};

export const UnavailableServiceCategory = ({ serviceCategoryName }: UnavailableServiceCategoryProps) => {
  const data = getServiceCategoryTranslations({ serviceCategoryName });

  if (!data) {
    return null;
  }
  return (
    <div className='flex gap-2'>
      <NoddiIcon name='Sad' color={colors.primary.black} size='large' />
      <span className='text-5 sm:text-6'>{data.name}</span>
    </div>
  );
};
