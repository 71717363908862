import { Route, Routes } from 'react-router-dom';
import routes from '../../../../appRoutes';
import Addons from './Steps/Addons/Addons';
import Car from './Steps/Car/Car';
import { SalesItems } from './Steps/SalesItem/SalesItem';
import { Summary } from './Steps/Summary/Summary';
import { TireHotel } from './Steps/TireHotel/TireHotel';

export function ExpandBookingRouter() {
  return (
    <Routes>
      <Route path={routes.expandBookingCar.getBasePath()} element={<Car />} />
      <Route path={routes.expandBookingSalesItem.getBasePath()} element={<SalesItems />} />
      <Route path={routes.expandBookingAddon.getBasePath()} element={<Addons />} />
      <Route path={routes.expandBookingTirehotel.getBasePath()} element={<TireHotel />} />
      <Route path={routes.expandBookingSummary.getBasePath()} element={<Summary />} />
    </Routes>
  );
}
