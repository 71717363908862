import { useIsMobile } from 'noddi-ui';
import { cn } from 'noddi-ui/src/helpers/utilts';
import { Outlet, useLocation } from 'react-router-dom';

import routes from '../../appRoutes';
import { CustomerHeaderNav } from './CustomerHeaderNav';

export const RouterLayout = ({ className }: { className?: string }) => {
  const { pathname } = useLocation();
  const isTimePickerStep = pathname.includes(routes.newBookingTimeSlotStep.getBasePath());
  const isMobile = useIsMobile();

  const applyTimePickerMobileGradient = isTimePickerStep && isMobile;

  return (
    <div
      className={cn(
        `min-h-screen ${applyTimePickerMobileGradient ? 'bg-pinto-to-white-gradient' : 'bg-pint-to-lighterPint-gradient'}`,
        className
      )}
    >
      <CustomerHeaderNav /> <Outlet />
    </div>
  );
};
