import { Trans, t } from '@lingui/macro';

import { WheelChangeImageCard } from './WheelChangeImageCard';

const NoBookingsCard = () => {
  return (
    <div className='flex flex-col gap-2 md:gap-4'>
      <h2 className='font-bold text-5 md:text-7'>
        <Trans>No bookings</Trans>
      </h2>
      <WheelChangeImageCard
        cardText={t`Tell us where your car is parked. We'll come to you, so you can spend your time on other things`}
      />
    </div>
  );
};
export default NoBookingsCard;
