import { Trans } from '@lingui/macro';
import { TireHotelContract, TireHotelOptionsForCar } from 'noddi-async/src/types';
import { NoddiBasicCard, NoddiButton } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';

import routes from '../../appRoutes';
import useCleanupBooking from '../../hooks/useCleanupBooking';
import { useTireHotelContext } from './BookingFlow/context';
import usePopulateStore from './BookingFlow/hooks/usePopulateStore';
import { TireStorageBookingSteps } from './BookingFlow/tireStorageBookingSteps';
import TireHotelSvg from './TireHotelSvg';

type ContractCardProps = {
  tireHotelContract: TireHotelContract;
  tireHotelOption?: TireHotelOptionsForCar;
};

const ContractCard = ({ tireHotelContract, tireHotelOption }: ContractCardProps) => {
  const navigate = useNavigate();

  const { setCurrentStep, setSelectedTireHotelContracts } = useTireHotelContext();
  const { cleanupBookingData } = useCleanupBooking();
  const { prePopulateSessionStorageFromTireHotelContracts } = usePopulateStore();

  const { bookingItemSalesItem } = tireHotelContract;

  const noneCanRenew = tireHotelOption?.canRenewTireHotel;
  const canTerminate = tireHotelOption?.canTerminateTireHotel;

  function prePopulateAndNavigate(to: 'renew' | 'terminate') {
    cleanupBookingData();
    prePopulateSessionStorageFromTireHotelContracts({ tireHotelContracts: [tireHotelContract] });
    setSelectedTireHotelContracts([tireHotelContract]);

    if (to === 'renew') {
      setCurrentStep(TireStorageBookingSteps.address);
      navigate(routes.newBookingAddressStep.getPath());
    }

    if (to === 'terminate') {
      setCurrentStep(TireStorageBookingSteps.returnChoices);
      navigate(routes.tireStorageCancelBooking.getPath());
    }
  }

  return (
    <NoddiBasicCard className='flex flex-col gap-3'>
      <div className='flex justify-between gap-6'>
        <div>
          <p className='font-bold text-5'>{`${tireHotelContract.car.make} ${tireHotelContract.car.model}`}</p>
          <p className='text-5'>{tireHotelContract.car.licensePlateNumber}</p>
        </div>
        <div className='flex flex-col gap-2'>
          {noneCanRenew && (
            <NoddiButton size='small' onClick={() => prePopulateAndNavigate('renew')}>
              <Trans>Order wheel change</Trans>
            </NoddiButton>
          )}
          {canTerminate && (
            <NoddiButton fullWidth variant='secondary' size='small' onClick={() => prePopulateAndNavigate('terminate')}>
              <Trans>Cancel agreement</Trans>
            </NoddiButton>
          )}
        </div>
      </div>
      <div className='flex items-end justify-between gap-4'>
        <div>
          <TireHotelSvg />
        </div>

        {!!bookingItemSalesItem && (
          <NoddiButton
            className='pr-0 pt-0'
            variant='link'
            endIcon='ArrowRight'
            onClick={() => navigate(routes.myBookingDetails.getPath({ id: bookingItemSalesItem.bookingId }))}
          >
            <Trans>See booking</Trans>
          </NoddiButton>
        )}
      </div>
    </NoddiBasicCard>
  );
};

export default ContractCard;
