import { useLocation, useNavigate } from 'react-router-dom';
import { navigateAndLog } from '../../../../BookingFlow/hooks/navigateAndLog';
import { useStepCompletionValidation } from '../../../../BookingFlow/hooks/useStepCompletionValidation';
import useExpandBookingPaths from './useExpandBookingPaths';

export const useExpandBookingNavigateOnInvalidStep = () => {
  const navigate = useNavigate();

  const { carPath, salesItemsPath, tireHotelPath } = useExpandBookingPaths();

  const { pathname: activeStep } = useLocation();

  const { isCarStepValid, isSalesItemStepValid, isWheelStorageStepValid } = useStepCompletionValidation();

  /**
   * This function is used to validate the current step in a multi-step process.
   * It checks the active step and performs validation accordingly. If the validation fails, the function logs a validation message and navigates to the failed step.
   **/
  const navigateOnInvalidStep = () => {
    if (activeStep === carPath) {
      return;
    }

    if (!isCarStepValid) {
      return navigateAndLog(activeStep, carPath, navigate);
    }

    if (activeStep === salesItemsPath) {
      return;
    }

    if (!isSalesItemStepValid) {
      return navigateAndLog(activeStep, salesItemsPath, navigate);
    }

    if (activeStep === tireHotelPath) {
      return;
    }
    // This is also safe for the case when no TH items are selected, as the function will return true when no TH items are selected
    if (!isWheelStorageStepValid) {
      navigateAndLog(activeStep, tireHotelPath, navigate);
    }
  };

  return { navigateOnInvalidStep };
};
