import { t } from '@lingui/macro';

import AddressPickerComponent from '../../../../components/BookingFlow/AddressPickerComponent';
import BookingScreen from '../../BookingScreen';
import { useStepCompletionValidation } from '../../hooks/useStepCompletionValidation';
import useOnNextButtonClick from './useOnNextButtonClick';

const Address = () => {
  const { navigateToNextStep } = useOnNextButtonClick();
  const { isAddressStepValid } = useStepCompletionValidation();

  return (
    <BookingScreen
      title={t`Where is your car parked?`}
      onNextClicked={navigateToNextStep}
      disableNextButton={!isAddressStepValid}
      hideShoppingCart
    >
      <AddressPickerComponent />
    </BookingScreen>
  );
};

export default Address;
