import { useNavigate } from 'react-router-dom';

import { useBookingContext } from '../../../../contexts/BookingContext';
import useFetchAddons from '../../../../hooks/ApiHooks/useFetchAddons';
import { GoogleTrackingService } from '../../../../services/GoogleTrackingService';
import { useCouponStore } from '../../../../stores/CouponStore';
import { GtmEvents } from '../../../../types/gtmTracking';
import { addonsStepPath, timeSlotStepPath } from '../../bookingFlowPaths';
import { useMembershipPrograms } from '../../hooks/useMembershipPrograms';

const useOnNextButtonClick = () => {
  const navigate = useNavigate();
  const { refetch: fetchAddons } = useFetchAddons();
  const { salesItems } = useBookingContext();
  const { data: membershipData } = useMembershipPrograms();
  const { coupons } = useCouponStore();

  const navigateToNextStep = async () => {
    const { data: addons } = await fetchAddons();

    const nextRoute = addons?.length ? addonsStepPath : timeSlotStepPath;

    if (nextRoute === timeSlotStepPath) {
      GoogleTrackingService.trackEvent({
        eventType: GtmEvents.beginCheckout,
        data: { salesItems, membershipData: membershipData ?? [], coupons }
      });
    }

    return navigate(nextRoute);
  };

  return { navigateToNextStep };
};

export default useOnNextButtonClick;
