import { NoddiBasicCard } from '../../../../atoms';
import { KeyValueRow } from '../../../Elements';

type Props = {
  length: number;
  vinNumber: string | undefined;
  weight: number;
  height: number;
  width: number;
  translations: {
    length: string;
    weight: string;
    height: string;
    width: string;
    vinChassi: string;
    generalInfo: string;
  };
};

const GeneralDetails = ({ length, vinNumber, weight, width, height, translations }: Props) => {
  return length || height || width || weight || vinNumber ? (
    <div className='flex flex-col gap-4'>
      <h6 className='font-bold text-5 md:text-7'>{translations.generalInfo}</h6>
      <NoddiBasicCard>
        {length && <KeyValueRow header={translations.length} value={`${length} mm`} />}
        {height && <KeyValueRow header={translations.height} value={`${height} mm`} />}
        {width && <KeyValueRow header={translations.width} value={`${width} mm`} />}
        {weight && <KeyValueRow header={translations.weight} value={`${weight} mm`} />}
        {vinNumber && <KeyValueRow header={translations.vinChassi} value={vinNumber} showDivider={false} />}
      </NoddiBasicCard>
    </div>
  ) : null;
};
export { GeneralDetails };
