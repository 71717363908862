import { useLanguageContext } from 'noddi-provider';
import { NoddiLanguageSelector, NoddiLogo, colors } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';

import { getSupportedLocales } from '../../App';
import routes from '../../appRoutes';
import NavWrapper from './NavWrapper';

export const BasicHeaderNav = () => {
  const { currentLanguage, setCurrentLanguage } = useLanguageContext();
  const navigate = useNavigate();

  return (
    <NavWrapper>
      <div className='flex w-full items-center justify-between gap-6'>
        <button onClick={() => navigate(routes.homepage.getPath())}>
          <NoddiLogo color={colors.primary.orange} width={67} height={19} />
        </button>
        <NoddiLanguageSelector
          supportedLanguages={getSupportedLocales()}
          currentLanguage={currentLanguage}
          setCurrentLanguage={setCurrentLanguage}
          dropDownOnRightSide
        />
      </div>
    </NavWrapper>
  );
};
