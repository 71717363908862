import { embed } from '@dintero/checkout-web-sdk';
import { NoddiCircularLoader } from 'noddi-ui';
import { CountryCodeType } from 'noddi-util';
import { useEffect, useRef, useState } from 'react';

const getLanguageForDintero = (countryCode: CountryCodeType) => {
  switch (countryCode) {
    case 'NO':
      return 'no';
    case 'SE':
      return 'sv';
    default:
      return 'en';
  }
};

export default function PaymentForm({ sessionId, countryCode }: { sessionId: string; countryCode: CountryCodeType }) {
  const ref = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    setIsLoading(true);
    embed({
      container: ref.current,
      sid: sessionId,
      language: getLanguageForDintero(countryCode)
    }).then(() => {
      setIsLoading(false);
      // Scroll to the embedded container once loading is complete
      setTimeout(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    });
  }, []);

  return (
    <div ref={ref} style={{ marginTop: 24 }}>
      {isLoading && <NoddiCircularLoader />}
    </div>
  );
}
