import { motion } from 'framer-motion';
import { useRef, useState } from 'react';
import { IconName } from '../atoms';
import { NoddiIcon } from '../atoms/NoddiIcon';
import { cn } from '../helpers/utilts';
import { useOnClickOutside } from '../hooks/useOnClickOutside';

export type NoddiDropdownOption<ValueType = string> = {
  label: string;
  value: ValueType;
};

export type NoddiDropdownProps<ValueType = string> = {
  options: NoddiDropdownOption<ValueType>[];
  placeholder: string;
  selectedOption?: NoddiDropdownOption<ValueType> | null;
  onSelect: (option: NoddiDropdownOption<ValueType>) => void;
  startIcon?: IconName;
};

const NoddiDropdown = <ValueType,>({
  options,
  placeholder,
  selectedOption,
  onSelect,
  startIcon
}: NoddiDropdownProps<ValueType>) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const openMenu = () => setIsOpen(true);
  const closeMenu = () => setIsOpen(false);

  const handleSelect = (option: NoddiDropdownOption<ValueType>) => {
    onSelect(option);
    closeMenu();
  };

  useOnClickOutside(dropdownRef, closeMenu);

  return (
    <div className='relative inline-block text-left'>
      <button
        onClick={openMenu}
        className='inline-flex w-full justify-center gap-x-1.5 rounded-md bg-primary-white px-3 py-2 font-semibold text-secondary-black shadow-sm ring-1 ring-systemColors-outlineStroke'
      >
        {startIcon && <NoddiIcon name={startIcon} />}
        {selectedOption?.label ?? placeholder}
        <span className='ml-auto'>
          <NoddiIcon name='AltArrowDown' />
        </span>
      </button>

      {isOpen && (
        <motion.div
          ref={dropdownRef}
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          transition={{ duration: 0.2 }}
          className='absolute right-0 z-10 mt-1 w-full origin-top-right rounded-md bg-primary-white shadow-lg ring-1 ring-systemColors-outlineStroke'
        >
          <div>
            {options.map((option) => (
              <span
                key={String(option.value)}
                onClick={(e) => {
                  e.preventDefault();
                  handleSelect(option);
                }}
                className={cn(
                  'block cursor-pointer px-4 py-2 text-primary-black hover:bg-systemColors-grey/15',
                  selectedOption?.value === option.value ? 'bg-systemColors-grey/10' : ''
                )}
              >
                {option.label}
              </span>
            ))}
          </div>
        </motion.div>
      )}
    </div>
  );
};

export { NoddiDropdown };
