import { t, Trans } from '@lingui/macro';
import { noddiAsync, URLKeys } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { CouponCard, NoddiExternalLink } from 'noddi-ui';
import { useParams } from 'react-router-dom';

import getCommonTranslations from '../../commonTranslations';
import { BenefitsLogin } from '../../components/BenefitsLogin';
import ContentWrapperWithQuery from '../../components/Layouts/ContentWrapperWithQuery';
import { REFERRAL_PROGRAM_INFO } from '../../constants/externalUrls';
import ActivateCampaign from '../Campaigns/ActivateCampaign';

const Referrals = () => {
  const params = useParams<{ slug: string }>();
  const { isLoggedIn, canActivateCouponsForNewUsersOnly, userData } = useAuthContext();
  const safeSlug = params.slug?.toLocaleLowerCase();

  const query = noddiAsync.useGet({ type: URLKeys.getCampaignFromSlug, input: { slug: safeSlug } });

  return (
    <ContentWrapperWithQuery
      query={query}
      subtitle={`${t`You have been invited by`} ${query.data?.name}`}
      title={`${t`Hurray`}🥳`}
      hideBackButton
      widerScreen
    >
      {({ data }) => {
        const isOwnReferralCode = params.slug === userData?.user.referralCode;
        const canNotActivateCoupons = isLoggedIn && data?.isForNewUserOnly && !canActivateCouponsForNewUsersOnly;

        return (
          <>
            {!isLoggedIn && <BenefitsLogin />}

            {canNotActivateCoupons && (
              <div className='mt-8 flex flex-col'>
                {isOwnReferralCode ? (
                  <h2 className='text-5'>
                    <Trans>You cannot activate your own referral code</Trans>
                  </h2>
                ) : (
                  <h4 className='mt-2 text-5'>
                    <Trans>Your user cannot activate coupons from this campaign</Trans>
                  </h4>
                )}

                <p className='mt-2'>
                  <Trans>This promotion is only for new users without existing coupons</Trans>
                </p>
              </div>
            )}

            {isLoggedIn && !canNotActivateCoupons && <ActivateCampaign campaign={data} />}

            {data.couponGroup.couponTemplates.map((couponTemplate) => (
              <CouponCard
                key={couponTemplate.id}
                namePublic={couponTemplate.namePublic}
                descriptionPublic={couponTemplate.descriptionPublic}
                amount={couponTemplate.amount}
                amountPercentage={couponTemplate.amountPercentage}
                containerSx={{ marginTop: '22px' }}
                translations={{
                  activated: getCommonTranslations().activated,
                  used: getCommonTranslations().used,
                  amount: getCommonTranslations().amount,
                  discount: getCommonTranslations().discount
                }}
              />
            ))}

            <NoddiExternalLink className='-mr-3 flex self-end' endIcon='ArrowRight' href={REFERRAL_PROGRAM_INFO}>
              Read more about our referral program
            </NoddiExternalLink>
          </>
        );
      }}
    </ContentWrapperWithQuery>
  );
};

export default Referrals;
