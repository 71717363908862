import {
  CountryCodeType,
  norwegianLicensePlateRegex,
  partialNorwegianLicensePlateRegex,
  swedishLicensePlateRegex
} from 'noddi-util';

export const isValidLicensePlateNumber = ({
  input,
  countryCode
}: {
  input: string;
  countryCode: CountryCodeType;
}): boolean => {
  if (countryCode === 'NO') {
    return input === '' || partialNorwegianLicensePlateRegex.test(input) || norwegianLicensePlateRegex.test(input);
  }
  return input === '' || swedishLicensePlateRegex.test(input);
};
