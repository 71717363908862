import { Stack, Typography } from '@mui/material';
import { URLKeys, noddiAsync } from 'noddi-async';
import { AvailableSalesItem } from 'noddi-async/src/types';
import { LoadingScreen, SelectableCard } from 'noddi-ui';

import { formatCurrencyAmount } from 'noddi-util';
import { ErrorPageWithTranslations } from '../../../../../components/ErrorPageWithTrans';
import { useBookingContext } from '../../../../../contexts/BookingContext';
import { getSalesItemIconBySlug } from '../../../../../helper/salesItems';
import useSelectSalesItem from '../../../../../hooks/useSelectSalesItem';

const ReturnChoices = () => {
  const { data, isPending, error } = noddiAsync.useGet({
    type: URLKeys.getAvailableTireHotelCancelationOptions,
    queryConfig: {
      staleTime: Infinity
    }
  });

  const {
    bookingInputData: { selectedCars }
  } = useBookingContext();
  const { selectSalesItemForMultipleCars, isSelected } = useSelectSalesItem();

  if (isPending) {
    return <LoadingScreen />;
  }
  if (error) {
    return <ErrorPageWithTranslations apiError={error} />;
  }

  const checkIfSelected = (salesItem: AvailableSalesItem) => {
    return selectedCars.some((car) => {
      return isSelected({ licensePlateNumber: car.licensePlateNumber, salesItem });
    });
  };

  return (
    <Stack direction='column' gap={2}>
      {data.map((salesItem) => {
        return (
          <SelectableCard
            key={salesItem.id}
            selected={checkIfSelected(salesItem)}
            onClick={(_) => {
              selectSalesItemForMultipleCars({
                licensePlateNumbers: selectedCars.map((car) => car.licensePlateNumber),
                salesItem,
                isAddon: false,
                queryParams: {
                  urlKey: URLKeys.getAvailableTireHotelCancelationOptions,
                  queryInput: {
                    group: 'tire-storage-cancel-options'
                  }
                }
              });
            }}
            mode='select'
          >
            <Stack alignItems='center'>
              <p className='mb-3 text-5 font-semibold'>{salesItem.name}</p>
              {getSalesItemIconBySlug(salesItem.slug)}
              <b>{formatCurrencyAmount(salesItem.price)}</b>

              <Typography textAlign='center'>{`${salesItem.description}`}</Typography>
            </Stack>
          </SelectableCard>
        );
      })}
    </Stack>
  );
};

export default ReturnChoices;
