import { i18n } from '@lingui/core';
import { storage } from 'noddi-util';

export const dynamicActivateLocale = async (locale: string) => {
  const { messages } = await import(`../locales/${locale}.po`);

  i18n.load(locale, messages);
  i18n.activate(locale);

  storage.local.setItem('locale', locale);
};
