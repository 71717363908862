import ArcticLogo from '../assets/images/arctic.png';
import GetAroundLogo from '../assets/images/getaround.png';
import IfLogo from '../assets/images/if.png';
import NafLogo from '../assets/images/naf-logo.svg';
import ParetoLogo from '../assets/images/pareto.png';

const CompanyLogos = {
  if: IfLogo,
  getaround: GetAroundLogo,
  pareto: ParetoLogo,
  naf: NafLogo,
  arctic: ArcticLogo
};

export function getImageSrc(slug?: string) {
  if (!slug) {
    return '';
  }
  // @ts-ignore
  return CompanyLogos[slug] ?? '';
}
