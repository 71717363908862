import { Trans, t } from '@lingui/macro';
import { ServerTypes } from 'noddi-async';
import { tracking } from 'noddi-provider';
import { NoddiButton, NoddiDialog } from 'noddi-ui';
import { cn } from 'noddi-ui/src/helpers/utilts';
import { formatCurrencyAmount } from 'noddi-util';
import { useState } from 'react';

import { useBookingContext } from '../../../../contexts/BookingContext';
import useSelectSalesItem from '../../../../hooks/useSelectSalesItem';
import { SelectedCar } from '../../interfaces';
import { IncompatibleDialog } from './IncompatibleDialog';

type ServiceItemProps = {
  name: string;
  price: number;
  description?: string;
  shortDescription?: string;
  car: SelectedCar;
  salesItem: ServerTypes.AvailableSalesItem;
  onSalesItemClick: () => void;
};

const SalesItem = ({
  name,
  description,
  shortDescription,
  price,
  car,
  salesItem,
  onSalesItemClick
}: ServiceItemProps) => {
  const [openIncompatibleDialog, setOpenIncompatibleDialog] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const { isSelected } = useSelectSalesItem();
  const isAlreadySelected = isSelected({ licensePlateNumber: car.licensePlateNumber, salesItem });
  const { incompatibleServiceCategories } = useBookingContext();

  const isCurrentlyIncompatible = incompatibleServiceCategories.find(
    (category) => category.serviceCategoryId === salesItem.serviceCategoryId
  );

  const isFree = price === 0;
  const priceFormatted = isFree ? t`Free` : formatCurrencyAmount(price);

  return (
    <>
      <IncompatibleDialog
        salesItem={salesItem}
        open={openIncompatibleDialog}
        onClose={() => {
          setOpenIncompatibleDialog(false);
        }}
      />
      <div
        className={cn(
          'relative flex flex-col gap-2 px-4 py-3 sm:px-6 sm:py-5',
          isAlreadySelected
            ? 'rounded-md bg-primary-purple text-primary-white'
            : '[&:not(:last-child)]:border-b-[1.5px] [&:not(:last-child)]:border-primary-purple'
        )}
      >
        <div className='flex justify-between gap-2'>
          <p className='text-5'>{name}</p>
        </div>
        <p className={cn('font-semibold', isFree && !isAlreadySelected && 'text-signal-success')}>{priceFormatted}</p>

        {shortDescription && <p>{shortDescription} </p>}
        <div className='flex w-full justify-end'>
          {isCurrentlyIncompatible ? (
            <NoddiButton
              className='pl-0 text-left'
              variant='link'
              onClick={() => {
                setOpenIncompatibleDialog(true);
              }}
            >
              <Trans>Why am I not able to select this?</Trans>
            </NoddiButton>
          ) : (
            <div className='flex items-center gap-2'>
              {description && (
                <NoddiButton
                  startIcon='ArrowSquareRightCorner'
                  variant={isAlreadySelected ? 'lightLink' : 'link'}
                  onClick={() => {
                    tracking.track('salesItemDescriptionDetailsClicked', { salesItemName: name });
                    setShowFullDescription(true);
                  }}
                >
                  <Trans>Details</Trans>
                </NoddiButton>
              )}
              <NoddiButton
                variant='secondary'
                startIcon={isAlreadySelected ? 'Minus' : 'Plus'}
                size='small'
                onClick={onSalesItemClick}
              >
                {isAlreadySelected ? <Trans>Remove</Trans> : <Trans>Add</Trans>}
              </NoddiButton>
            </div>
          )}
        </div>
      </div>
      <NoddiDialog title={name} onClose={() => setShowFullDescription(false)} open={showFullDescription}>
        {description}
      </NoddiDialog>
    </>
  );
};

export { SalesItem };
