import { t } from '@lingui/macro';
import { CountryCode, CountryCodeType } from 'noddi-util';

export interface LicensePlateCountryOption {
  value: CountryCodeType;
  label: string;
  flag: string;
}

export function getLicensePlateCountryOptions(): LicensePlateCountryOption[] {
  return [
    { value: CountryCode.NORWAY, label: t`Norway`, flag: '🇳🇴' },
    { value: CountryCode.SWEDEN, label: t`Sweden`, flag: '🇸🇪' }
  ];
}
