/* eslint-disable @typescript-eslint/no-explicit-any */

export function isNotNull<T>(value: T | null): value is T {
  return value !== null;
}

export function isNotNullOrUndefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export const isObjectNotNull = <T extends Record<string, any>>(obj: T, excludeKeys: Array<keyof T> = []): obj is T => {
  return Object.entries(obj).every(([key, value]) => {
    if (excludeKeys.includes(key)) {
      return true;
    }
    return isNotNullOrUndefined(value);
  });
};
