import { Error404Page } from 'noddi-ui';

import getCommonTranslations from '../commonTranslations';

export const Error404PageWithTranslations = () => {
  const errorPageTranslations = {
    pageNotFoundHeading: getCommonTranslations().pageNotFoundHeading,
    pageNotFoundMessage: getCommonTranslations().pageNotFoundMessage,
    goBack: getCommonTranslations().goBack
  };

  return <Error404Page translations={errorPageTranslations} />;
};
