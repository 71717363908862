import { mapErrorCodeToUiMessage } from '../components';
import { NoddiAsyncError } from '../components/Elements/Errors/ErrorMessage';
import { errorCodes as errorCodesWithoutTrans } from '../errorCodes';
import { NoddiFeedbackBoxSize } from '../molecules';
import { useToast } from './ToastProvider';

export const useNoddiToast = () => {
  const { addToast } = useToast();

  const success = (heading: string, description?: string, size?: NoddiFeedbackBoxSize) =>
    addToast('success', heading, description, size);
  const error = (heading: string, description?: string, size?: NoddiFeedbackBoxSize) =>
    addToast('error', heading, description, size);
  const warning = (heading: string, description?: string, size?: NoddiFeedbackBoxSize) =>
    addToast('warning', heading, description, size);
  const asyncError = (
    e: NoddiAsyncError,
    errorCodes?: Record<string, string> & { genericErrorMessage: string },
    size?: NoddiFeedbackBoxSize
  ) =>
    error(
      mapErrorCodeToUiMessage({
        error: e,
        errorCodes: errorCodes ?? errorCodesWithoutTrans
      }),
      undefined,
      size
    );

  const noddiToast = { success, error, warning, asyncError };

  return { noddiToast };
};
