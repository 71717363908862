import { Trans } from '@lingui/macro';
import { NoddiBasicCard, NoddiButton } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';

import routes from '../../appRoutes';
import CarItemCardSvg from './CarSvg';

interface CarItemCardProps {
  carId: number;
  carName: string;
  licensePlate: string;
}
const CarItemCard = ({ carName, licensePlate, carId }: CarItemCardProps) => {
  const navigate = useNavigate();

  return (
    <NoddiBasicCard className='flex flex-col justify-center gap-3'>
      <div className='flex flex-row justify-between gap-5'>
        <div className='flex flex-col text-5'>
          <p className='font-bold'>{carName}</p>
          <p>{licensePlate}</p>
        </div>
        <div className='flex flex-col items-center gap-2'>
          <div>
            <CarItemCardSvg />
          </div>
          <NoddiButton
            endIcon='ArrowRight'
            className='pb-0 pr-0'
            variant='link'
            onClick={() => navigate(routes.myCarDetails.getPath({ carId }))}
          >
            <Trans>See details</Trans>
          </NoddiButton>
        </div>
      </div>
    </NoddiBasicCard>
  );
};

export default CarItemCard;
