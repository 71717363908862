/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextFieldProps } from '@mui/material';
import { Field, FieldInputProps, FormikProps } from 'formik';
import { forwardRef } from 'react';

import { NoddiTextInput } from '../../Inputs';
import { FormFieldErrorMessage } from '../FormFieldErrorMessage';

export type NoddiFormTextInputProps = TextFieldProps & {
  name: string;
  label?: string;
};

type NoddiFieldAttributesProps = {
  field: FieldInputProps<string | number>;
  form: FormikProps<any>;
};

const NoddiFormTextInput = forwardRef<HTMLInputElement, NoddiFormTextInputProps>((props, ref) => {
  const { name, onChange, value, ...rest } = props;

  return (
    <Field name={name}>
      {({ field, form }: NoddiFieldAttributesProps) => (
        <div role='combobox' data-testid='noddi-form-text-input-container'>
          <NoddiTextInput
            {...field}
            {...rest}
            ref={ref}
            error={Boolean(form.touched[name] && form.errors[name])}
            // value has priority here as for phone input we have to listen to value changed by country selector,
            // but for standard text input we are not using values as its coming from formik structure
            value={field.value ?? ''}
            onChange={async (event) => {
              const newValue = event.target.value;

              if (rest.type === 'number' && newValue !== '') {
                await form.setFieldValue(name, Number(newValue));
              } else if (newValue === '') {
                // Handle empty string for number input
                await form.setFieldValue(name, null);
              } else {
                // Handle string input
                await form.setFieldValue(name, newValue);
              }

              onChange?.(event);
            }}
          />

          <FormFieldErrorMessage name={name} component='div' />
        </div>
      )}
    </Field>
  );
});

export { NoddiFormTextInput };
