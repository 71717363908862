export const WheelSetStatus = {
  MissingMeasurements: `MissingMeasurements`,
  WornOut: `WornOut`,
  Satisfactory: `Satisfactory`,
  SoonWornOut: `SoonWornOut`
} as const;

export type WheelSetStatusType = keyof typeof WheelSetStatus;

export const Tires = {
  summer: 'summer',
  winter: 'winter'
} as const;

export type TireType = keyof typeof Tires;
