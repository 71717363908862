import { t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { BookingInfoStatus, BookingInfo as BookingInfoType } from 'noddi-async/src/types';
import { LoadingScreen } from 'noddi-ui';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ErrorPageWithTranslations } from '../../components/ErrorPageWithTrans';
import ContentWrapper from '../../components/Layouts/ContentWrapper';
import { BookingContentBasedOnStatus } from './BookingContentBasedOnStatus';
import FeedbackAfterCompletedBooking from './FeedbackAfterCompletedBooking';
import { JobCompletedAndPaymentLeft } from './JobCompletedAndPaymentLeft';
import PaymentInfo from './PaymentInfo';

function getContent({
  bookingInfo,
  currentEstimatedArrivalTime,
  orderId
}: {
  bookingInfo: BookingInfoType;
  currentEstimatedArrivalTime?: { start?: string; end?: string };
  orderId: number;
}) {
  const isInvoiced = bookingInfo.order.status === 'Invoiced';
  const amountDue = bookingInfo.order.amountDue;
  const hasPayed = isInvoiced && amountDue <= 0;
  const jobCompletedAndPaymentLeft = isInvoiced && amountDue > 0;
  const showFeedbackContent =
    !bookingInfo.feedback && [BookingInfoStatus.COMPLETED].includes(bookingInfo.status) && hasPayed;

  function getTitle() {
    if (showFeedbackContent) {
      return t`Tell us about your experience`;
    }
    if (jobCompletedAndPaymentLeft) {
      return t`Job completed`;
    }
    return t`Your booking`;
  }
  const Title = () => <h1 className='mt-6 text-11 sm:text-13'>{getTitle()}</h1>;

  if (showFeedbackContent) {
    return (
      <>
        <Title />
        <FeedbackAfterCompletedBooking bookingId={bookingInfo.id} />
      </>
    );
  }

  if (hasPayed) {
    return (
      <>
        <Title />
        <PaymentInfo orderId={orderId} paymentMethod={bookingInfo.order.paymentMethod} />
      </>
    );
  }

  if (jobCompletedAndPaymentLeft) {
    return (
      <>
        <Title />
        <JobCompletedAndPaymentLeft bookingInfo={bookingInfo} orderId={orderId} />
      </>
    );
  }

  // if none of the above is true we show the booking content based on status
  return (
    <>
      <Title />
      <div className='mt-4 w-full'>
        <BookingContentBasedOnStatus
          status={bookingInfo.status}
          bookingInfo={bookingInfo}
          currentEstimatedArrivalTime={currentEstimatedArrivalTime}
        />
      </div>
    </>
  );
}

const BookingInfo = () => {
  const data = useParams<{ slug: string }>();
  const [orderId, setOrderId] = useState<number | null>(null);

  const {
    data: bookingInfo,
    isPending: isBookingInfoPending,
    isSuccess: isBookingInfoSuccess,
    error: bookingInfoError
  } = noddiAsync.useGet({
    type: URLKeys.getBookingInfo,
    input: { slug: data.slug ?? '' }
  });

  useEffect(() => {
    if (!bookingInfo) {
      return;
    }
    setOrderId(bookingInfo.order.id);
  }, [bookingInfo]);

  const shouldRefetch = isBookingInfoSuccess && !!bookingInfo.delayInSeconds;

  const { data: currentEstimatedArrivalTime } = noddiAsync.useGet({
    type: URLKeys.getCurrentEstimatedArrivalTime,
    input: { slug: data.slug ?? '' },
    queryConfig: {
      refetchInterval: shouldRefetch ? 60000 * 2 : false,
      refetchOnWindowFocus: shouldRefetch
    }
  });

  if (isBookingInfoPending) {
    return <LoadingScreen />;
  }

  if (bookingInfoError) {
    return <ErrorPageWithTranslations apiError={bookingInfoError} />;
  }

  return (
    <ContentWrapper>
      {getContent({ bookingInfo, currentEstimatedArrivalTime, orderId: orderId as number })}
    </ContentWrapper>
  );
};

export default BookingInfo;
