import { MembershipDiscountProps } from 'noddi-async/src/types';
import { roundNumber } from 'noddi-util';

import { ActiveCoupon } from '../../../../stores/CouponStore';

export const getNewPriceForSalesItem = ({
  price,
  discount,
  coupon
}: {
  price: number;
  discount?: MembershipDiscountProps | undefined;
  coupon?: ActiveCoupon | undefined;
}) => {
  let discountAmount = 0;
  let couponAmount = 0;

  if (discount?.percentage) {
    discountAmount = (Number(discount.percentage) / 100) * price;
  } else if (discount?.price) {
    discountAmount = discount.price;
  }

  if (coupon?.amountPercentage) {
    couponAmount = (Number(coupon.amountPercentage) / 100) * price;
  } else if (coupon?.amount) {
    couponAmount = coupon?.amount;
  }

  return roundNumber({
    number: price - discountAmount - couponAmount,
    decimalPlaces: 2
  });
};
