import { TireOptionQuote } from 'noddi-async/src/types/shared/quotes';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

type TireSelection = { selectedOffer: TireOptionQuote; quantity: number };

interface TireOfferState {
  tireSelection?: TireSelection;
  setTireSelection: (tireSelection: TireSelection) => void;
}

const initialStateValues = {
  tireSelection: undefined
};

export const useTireOfferStore = create<TireOfferState>()(
  persist(
    devtools((set) => ({
      ...initialStateValues,
      setTireSelection: (tireSelection) => set({ tireSelection })
    })),
    {
      name: 'tireOfferStore',
      storage: createJSONStorage(() => sessionStorage)
    }
  )
);
