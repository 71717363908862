import { t } from '@lingui/macro';
import { useAuthContext } from 'noddi-provider';
import { NoddiIcon, colors } from 'noddi-ui';
import { NavItem } from 'noddi-ui/src/types/navItem';

import routes from '../appRoutes';

const useNavItems = () => {
  const { hasTireHotel, hasMemberships } = useAuthContext();

  const navItems: NavItem[] = [
    {
      items: [
        {
          path: routes.homepage.getPath(),
          title: t`My profile`,
          icon: <NoddiIcon name='UserCircle' color={colors.primary.white} size='medium' />
        }
      ]
    },
    {
      items: [
        {
          path: routes.bookings.getPath(),
          title: t`Bookings`,
          icon: <NoddiIcon name='Calendar' color={colors.primary.white} size='medium' />
        }
      ]
    },
    {
      items: [
        {
          path: routes.myCoupons.getPath(),
          title: t`Bonus`,
          icon: <NoddiIcon size='medium' color={colors.primary.white} name='Star' />
        }
      ]
    },
    {
      items: [
        {
          path: routes.myMembershipPrograms.getPath(),
          title: t`Membership benefits`,
          icon: <NoddiIcon name='Plus' size='medium' color={colors.primary.white} />,
          shouldShow: () => hasMemberships ?? false
        }
      ]
    },
    {
      items: [
        {
          path: routes.tireStorage.getPath(),
          title: t`Tire hotel`,
          icon: <NoddiIcon name='Home' size='medium' color={colors.primary.white} />,
          shouldShow: () => hasTireHotel ?? false
        }
      ]
    }
  ];

  const getNavItems = () => navItems;

  return { getNavItems };
};

export default useNavItems;
