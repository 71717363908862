import { Trans, t } from '@lingui/macro';
import Typography from '@mui/material/Typography';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { EuCheckDetails, GeneralDetails, LoadingScreen, TireDetails, Tires, calculateWheelSetStatus } from 'noddi-ui';
import { useNavigate, useParams } from 'react-router-dom';

import routes from '../../appRoutes';
import getCommonTranslations from '../../commonTranslations';
import ContentWrapper from '../../components/Layouts/ContentWrapper';

const CarDetailPage = () => {
  const { currentUserGroupId: userGroupId } = useAuthContext();
  const navigate = useNavigate();
  const { carId } = useParams();

  if (!carId) {
    navigate(routes.homepage.getPath());
    return null;
  }

  const { data: car, isPending } = noddiAsync.useGet({
    type: URLKeys.getUserGroupCar,
    input: { userGroupId, carId },
    queryConfig: { enabled: !!userGroupId, staleTime: Infinity }
  });

  if (isPending) {
    return <LoadingScreen />;
  }
  if (!car) {
    return (
      <ContentWrapper title={t`Your car`}>
        <Typography textAlign='center' variant='subtitle1'>
          <Trans>Did not find any information about this vehicle</Trans>
        </Typography>
      </ContentWrapper>
    );
  }

  const title = `${car.make} ${car.model} -  ${car.licensePlateNumber}`;
  const { euControlPrevious, euControlNext, length, weight, width, height, vinNumber } = car;

  const statusWinterWheelSet = calculateWheelSetStatus(car, Tires.winter);
  const statusSummerWheelSet = calculateWheelSetStatus(car, Tires.summer);

  return (
    <ContentWrapper title={title}>
      <div className='grid grid-cols-1 gap-8 text-primary-darkPurple md:grid-cols-2'>
        <TireDetails
          onClick={() => navigate(routes.myCarWheelMeasurements.getPath({ carId }))}
          statusWinterWheelSet={statusWinterWheelSet}
          statusSummerWheelSet={statusSummerWheelSet}
          translations={getCommonTranslations().wheelMeasurement}
        />
        <GeneralDetails
          length={length}
          height={height}
          weight={weight}
          width={width}
          vinNumber={vinNumber}
          translations={{
            length: getCommonTranslations().length,
            height: getCommonTranslations().height,
            width: getCommonTranslations().width,
            weight: getCommonTranslations().weight,
            vinChassi: getCommonTranslations().vinChassi,
            generalInfo: getCommonTranslations().generalInfo
          }}
        />

        <EuCheckDetails
          euControlNext={euControlNext}
          euControlPrevious={euControlPrevious}
          translations={{
            nextCheck: getCommonTranslations().nextCheck,
            lastApproval: getCommonTranslations().lastApproval,
            euCheck: getCommonTranslations().euCheck
          }}
        />
      </div>
    </ContentWrapper>
  );
};

export default CarDetailPage;
