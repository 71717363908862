import { Trans, t } from '@lingui/macro';
import { captureException } from '@sentry/react';
import { URLKeys, noddiAsync } from 'noddi-async';
import { AvailableBookingTimeWindow, MembershipProgramProps } from 'noddi-async/src/types';
import { commonTrackingEvents, tracking, useAuthContext } from 'noddi-provider';
import { ErrorMessage, NoddiButton } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';

import { ApiErrorMessageWithTrans } from '../../../../components/ApiErrorMessageWithTrans';
import { routeToConfirmation } from '../../../../helper/confirmation';
import useBookingPayload from '../../../../hooks/useBookingPayload';
import useCleanupBooking from '../../../../hooks/useCleanupBooking';
import { GoogleTrackingService } from '../../../../services/GoogleTrackingService';
import { useWheelStore } from '../../../../stores/WheelStore';
import { GtmEvents } from '../../../../types/gtmTracking';

type ConfirmBookingProps = {
  membershipPrograms: MembershipProgramProps[] | undefined;
};

const ConfirmBooking = ({ membershipPrograms }: ConfirmBookingProps) => {
  // ensure user is logged in and user group is selected
  const { isLoggedIn } = useAuthContext();
  const { cleanupBookingData } = useCleanupBooking();

  const navigate = useNavigate();

  const { selectedWheelStorageOptions } = useWheelStore();
  const { createBookingPayload, getTrackingData } = useBookingPayload();

  // check that all selected wheel storage options have a storage type and pick up address
  if (
    selectedWheelStorageOptions.length > 0 &&
    !selectedWheelStorageOptions.every((option) => !!option.storageType && !!option.pickUpAddress)
  ) {
    return (
      <ErrorMessage
        message={t`Not all tire hotel options are provided, please go back and typ correct info. If problem persists, please contact support.`}
      />
    );
  }

  const {
    mutateAsync: createNewBooking,
    isPending,
    error
  } = noddiAsync.usePost({
    type: URLKeys.postBooking,
    queryConfig: {
      onSuccess: async (data) => {
        try {
          const { coupons, salesItems, time, cars } = getTrackingData();

          tracking.track(commonTrackingEvents.bookingCreated, {
            numberOfCars: cars.length,
            numberOfCouponsUsed: coupons.length,
            salesItems,
            membershipData: membershipPrograms
          });

          GoogleTrackingService.trackEvent({
            eventType: GtmEvents.purchase,
            data: {
              salesItems,
              coupons,
              time: time as AvailableBookingTimeWindow,
              membershipData: membershipPrograms
            }
          });
        } catch (error) {
          captureException(error);
        }

        cleanupBookingData();
        routeToConfirmation({ navigate, id: data.data.id });
      }
    }
  });

  const onSubmit = async () => {
    const bookingParams = createBookingPayload(membershipPrograms);

    await createNewBooking(bookingParams);
  };

  return (
    <div className='flex w-full items-center justify-end gap-2'>
      {error && (
        <div className='-mt-2'>
          <ApiErrorMessageWithTrans error={error} isSmallVersion />
        </div>
      )}

      <NoddiButton
        variant='success'
        loading={isPending}
        disabled={isPending || !isLoggedIn}
        onClick={onSubmit}
        data-testid='confirm-booking'
      >
        <Trans>Confirm booking</Trans>
      </NoddiButton>
    </div>
  );
};

export default ConfirmBooking;
