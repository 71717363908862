import { t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { LoadingScreen } from 'noddi-ui';
import { useState } from 'react';

import { ErrorPageWithTranslations } from '../../../../components/ErrorPageWithTrans';
import { useBookingContext } from '../../../../contexts/BookingContext';
import { useWheelStore } from '../../../../stores/WheelStore';
import BookingScreen from '../../BookingScreen';
import { useStepCompletionValidation } from '../../hooks/useStepCompletionValidation';
import WheelStorageSelector from './WheelStorageSelector';
import useOnNextButtonClick from './useOnNextButtonClick';

const WheelStoragePageContent = () => {
  const { isWheelStorageStepValid } = useStepCompletionValidation();

  const [isAddonsLoading, setIsAddonsLoading] = useState(false);

  const { navigateToNextStep } = useOnNextButtonClick();

  async function onNextClicked() {
    setIsAddonsLoading(true);
    await navigateToNextStep();
    setIsAddonsLoading(false);
  }

  return (
    <BookingScreen
      onNextClicked={onNextClicked}
      disableNextButton={!isWheelStorageStepValid}
      title={t`Where are your wheels stored?`}
    >
      {isAddonsLoading ? <LoadingScreen /> : <WheelStorageList />}
    </BookingScreen>
  );
};

export const WheelStorageList = () => {
  const { salesItems } = useBookingContext();

  const { getWheelStorageItems } = useWheelStore();
  const wheelStorageSalesItems = getWheelStorageItems(salesItems);

  const { data, isPending, error } = noddiAsync.useGet({
    type: URLKeys.getWheelStorageSalesItemsOptions
  });

  if (error) {
    return <ErrorPageWithTranslations />;
  }

  if (isPending) {
    return <LoadingScreen />;
  }

  return (
    <div className='flex flex-col gap-4'>
      {wheelStorageSalesItems.map((item) => (
        <WheelStorageSelector
          key={item.licensePlateNumber}
          licensePlateNumber={item.licensePlateNumber}
          wheelStorageOptions={data}
        />
      ))}
    </div>
  );
};

export default WheelStoragePageContent;
