import { ServiceCategoryFromAddress } from 'noddi-async/src/types';
import { NoddiBasicCard } from 'noddi-ui';

import { AvailableServiceCategory } from './AvailableServiceCategory';
import { UnavailableServiceCategory } from './UnavailableServiceCategory';

type Props = {
  address?: string;
  serviceCategories: ServiceCategoryFromAddress[];
  unavailableServiceCategories: string[];
  navigateToNextStepWhenAvailableService?: () => void;
  translations: {
    from: string;
    hooray: string;
    youCanBookTheseServicesToYourAdress: string;
    weDoNotOfferTheseServicesAtThisAddress: string;
  };
};

const ServiceAvailabilityInfo = ({
  address,
  serviceCategories,
  unavailableServiceCategories,
  navigateToNextStepWhenAvailableService,
  translations
}: Props) => {
  if (!address) {
    return <></>;
  }

  const hasJustLegitimateServiceCategories = unavailableServiceCategories?.some(
    (item) => item === 'Wheel change' || item === 'Car wash' || item === 'Wheel storage'
  );

  return (
    <NoddiBasicCard className='flex flex-col gap-4'>
      <p className='text-6 sm:text-8'>
        {translations.hooray}
        🎉
      </p>
      <p className='text-5 sm:text-6'>{translations.youCanBookTheseServicesToYourAdress}</p>
      <div className='flex flex-col gap-6'>
        {serviceCategories.map((serviceCategory, index) => (
          <AvailableServiceCategory
            translations={{
              from: translations.from
            }}
            navigateToNextStepWhenAvailableService={navigateToNextStepWhenAvailableService}
            key={index}
            serviceCategory={serviceCategory}
          />
        ))}
      </div>
      {unavailableServiceCategories.length > 0 && hasJustLegitimateServiceCategories && (
        <div className='flex flex-col gap-4'>
          <div className='h-0.25 bg-primary-purple/90 opacity-45' />
          <p>{translations.weDoNotOfferTheseServicesAtThisAddress}</p>
          <div className='flex flex-col gap-6'>
            {unavailableServiceCategories.map((serviceCategoryName, index) => (
              <UnavailableServiceCategory key={index} serviceCategoryName={serviceCategoryName} />
            ))}
          </div>
        </div>
      )}
    </NoddiBasicCard>
  );
};

export { ServiceAvailabilityInfo };
