import { t, Trans } from '@lingui/macro';
import { TireOptionQuote } from 'noddi-async/src/types/shared/quotes';
import { NoddiBasicCard, NoddiButton, NoddiDropdown, ShowMoreDescription } from 'noddi-ui';
import { formatCurrencyAmount } from 'noddi-util';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import routes from '../../appRoutes';
import TireImage from '../../assets/images/tire.png';
import { useTireOfferStore } from './TireOfferStore';

interface TireSelectionCardProps {
  tireOption: TireOptionQuote;
  tireDimensions: string;
  slug: string;
  setTireDimensionsModalOpen: (isOpen: boolean) => void;
}
const TireSelectionCard = ({
  tireOption,
  tireDimensions,
  slug,
  setTireDimensionsModalOpen
}: TireSelectionCardProps) => {
  const { setTireSelection } = useTireOfferStore();
  const navigate = useNavigate();
  const quantityOptions = Array.from({ length: tireOption.maximumQuantity }, (_, i) => ({
    label: `${i + 1} stk`,
    value: i + 1
  }));

  const [quantity, setQuantity] = useState(tireOption.defaultQuantity);

  function onSelectTireOption() {
    setTireSelection({ selectedOffer: tireOption, quantity });
    navigate(routes.tireOfferSummary.getPath({ slug }));
  }

  return (
    <NoddiBasicCard className='flex flex-col gap-3'>
      <div className='flex gap-3'>
        <img src={TireImage} className='h-[121px] w-[91px]' />
        <div className='mt-3 flex flex-col'>
          <p className='font-bold text-3'>{tireOption.brand}</p>
          <p className='font-bold text-5'>{tireOption.model}</p>
          <div className='flex items-center'>
            <p>{tireDimensions}</p>
            <NoddiButton
              className='pl-2'
              endIcon='AltArrowDown'
              variant='link'
              size='small'
              onClick={() => setTireDimensionsModalOpen(true)}
            >
              <Trans>Edit</Trans>
            </NoddiButton>
          </div>
        </div>
      </div>
      <ShowMoreDescription trimLimit={200} description={tireOption.description} />
      <div>
        <p className='font-bold'>{formatCurrencyAmount(tireOption.price.amount * quantity)}</p>
        <p className='text-3'>
          {formatCurrencyAmount(tireOption.price.amount)} <Trans>per tire</Trans>
        </p>
      </div>

      <div className='flex gap-4'>
        <NoddiDropdown
          options={quantityOptions}
          onSelect={(item) => setQuantity(item.value)}
          placeholder={`${quantity} ${t`pcs`}`}
        />
        <NoddiButton onClick={onSelectTireOption}>{t`Select`}</NoddiButton>
      </div>
    </NoddiBasicCard>
  );
};

export default TireSelectionCard;
