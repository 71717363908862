import { getLongAddressName } from 'noddi-ui';

import { GtmEvents } from '../../types/gtmTracking';
import { buildEcommercePayload } from './buildEcommercePayload';
import {
  AddShippingInfoEvent,
  AddToCartEvent,
  BeginCheckoutEvent,
  NoServiceOfferedEvent,
  PurchaseEvent,
  ViewEvent
} from './types';

type EventPayloads =
  | ViewEvent
  | AddToCartEvent
  | BeginCheckoutEvent
  | AddShippingInfoEvent
  | PurchaseEvent
  | NoServiceOfferedEvent;

const buildPayloadForEvent = (event: EventPayloads) => {
  switch (event.eventType) {
    case GtmEvents.noServiceOffered:
      return { event: event.eventType };
    case GtmEvents.viewItem: {
      const { address, serviceArea } = event.data;

      return {
        event: event.eventType,
        available_services: serviceArea.serviceCategories.map((service) => service.name),
        unavailable_services: serviceArea.unavailableServiceCategories,
        ecommerce: {
          items: [
            {
              item_id: address.id ?? null,
              item_name: getLongAddressName(address)
            }
          ]
        }
      };
    }
    case GtmEvents.addToCart:
      return { event: event.eventType };

    case GtmEvents.beginCheckout: {
      const { currency, value, items } = buildEcommercePayload(event);
      return {
        event: event.eventType,
        ecommerce: {
          currency,
          value,
          items
        }
      };
    }

    case GtmEvents.addShippingInfo: {
      const { currency, value, items } = buildEcommercePayload(event);
      const { time } = event.data;
      return {
        event: event.eventType,
        time: {
          start: time.startPublic,
          end: time.endPublic,
          price: time.price
        },
        ecommerce: {
          currency,
          value,
          items
        }
      };
    }
    case GtmEvents.purchase: {
      const { currency, value, items } = buildEcommercePayload(event);
      const { time } = event.data;

      return {
        event: event.eventType,
        time: {
          start: time.startPublic,
          end: time.endPublic,
          price: time.price
        },
        ecommerce: {
          currency,
          value,
          items
        }
      };
    }
    default:
      console.error('Event type not found');
      return null;
  }
};

const trackEvent = (event: EventPayloads) => {
  const payload = buildPayloadForEvent(event);
  if (!payload) {
    return;
  }
  if (import.meta.env.MODE === 'production') {
    return window.dataLayer.push(payload);
  }

  return;
};

export const GoogleTrackingService = {
  trackEvent
};
