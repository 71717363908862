import { Trans } from '@lingui/macro';
import { NoddiBasicCard, NoddiExternalLink } from 'noddi-ui';

import { ContactUs } from '../../components/ContactUs';
import { MEMBERSHIP_PROGRAM_INFO } from '../../constants/externalUrls';

const NoMemberShip = () => (
  <NoddiBasicCard className='flex flex-col gap-5'>
    <p className='font-bold text-5'>
      <Trans>No membership found</Trans> 😭
    </p>

    <div>
      <Trans>You can read more about our membership programs</Trans>{' '}
      <NoddiExternalLink className='p-0' href={MEMBERSHIP_PROGRAM_INFO}>
        <Trans>here</Trans>
      </NoddiExternalLink>
    </div>

    <div>
      <ContactUs />
    </div>
  </NoddiBasicCard>
);
export default NoMemberShip;
