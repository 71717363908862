import { t } from '@lingui/macro';

const getCommonTranslations = () => ({
  chooseFromYourAddresses: t`Choose from your addresses?`,
  couldNotFindAddress: t`We're sorry, but we couldn't find the address you entered. Please double-check the address for any typos or missing details and try again.`,
  addressNotFound: t`No addresses found`,
  tryAgainWithStreetNumber: t`Please ensure the street number is included in the address`,
  chooseFromAnotherAddress: t`Choose from another address?`,
  noServiceOfferedNotice: t`Sorry, we do not currently deliver services to your address. Try another address, or try again at a later time`,
  somethingWentWrongWhileFetchingAddress: t`Something went wrong with fetching the addresses, please try again`,
  noStreetNumberNotice: t`Please enter an address with a street number so that we can check if we can come to you!`,
  pageNotFoundHeading: t`Page Not Found`,
  pageNotFoundMessage: t` The page you are looking for has either been moved or no longer exists. If we have linked incorrectly, we would be very happy if you send us a message.`,
  goBack: t`Go Back`,
  from: t`from`,
  hooray: t`Hooray!`,
  youCanBookTheseServicesToYourAdress: t`You can book these services to your adress`,
  weDoNotOfferTheseServicesAtThisAddress: t`We do not offer these services at this address`,
  length: t`Length`,
  weight: t`Weight`,
  height: t`Height`,
  width: t`Width`,
  vinChassi: t`VIN/Chassi`,
  generalInfo: t`General info`,
  nextCheck: t`Next check`,
  lastApproval: t`Last approval`,
  euCheck: t`EU check`,
  wheelMeasurement: {
    tires: t`Tires`,
    seeMeasurements: t`See measurements`,
    summerTires: t`Summer tires`,
    winterTires: t`Winter tires`,
    leftFront: t`Left front`,
    rightFront: t`Right front`,
    leftBack: t`Left back`,
    rightBack: t`Right back`,
    measuredAt: t`Measured at`,
    missingMeasurements: t`Missing measurements`,
    inAGoodCondition: t`In good condition`,
    soonWornOut: t`Soon worn out`,
    wornOut: t`Worn out`
  },
  add: t`Add`,
  regNumberLabel: t`E.g. AB12345`,
  regNumberLabelNO: t`E.g. AB12345`,
  regNumberLabelSE: t`E.g. ABC123`,
  regNumberPlaceholderNO: t`AB12345`,
  regNumberPlaceholderSE: t`ABC123`,
  myCars: t`My cars`,
  amount: t`Amount`,
  discount: t`Discount`,
  used: t`Used`,
  activated: t`Activated`,
  enterCode: t`Enter code`,
  confirm: t`Confirm`,
  standardsForWinterAndSummerTires: t`Standards for winter and summer tires`,
  winterTiresMinimumNotice: t`In Norway, the minimum requirement for tread depth on winter tires is 3 mm. It is illegal to drive with winter tires that are under 3 mm. We recommend changing tires when the tread depth is below 4 mm. When the tread depth is below 4 mm, the braking distance increases considerably, and the tire loses its performance.`,
  summerTiresMinimumNotice: t`In Norway, the minimum requirement for tread depth on summer tires is 1.6 mm. It is illegal to drive with summer tires under 1.6 mm. We recommend changing tires when the tread depth is below 3 mm. When the tread depth is below 3 mm, the braking distance increases considerably, and the tire loses its performance.`,

  smsLoginTranslations: {
    enterNumberToReceiveVerificationCode: t`Enter number to receive a verification code`,
    resend: t`Resend`,
    sendCode: t`Send code`,
    didntReceiveSms: t`Didn't receive sms?`,
    enterCode: t`Enter code`,
    confirm: t`Confirm`
  },
  registrationTranslations: {
    thisFieldIsRequired: t`This field is required`,
    thisFieldMustBeAtLeast2CharactersLong: t`This field must be at least 2 characters long`,
    mustProvideAValidEmailAddress: t`Must provide a valid email address`,
    passwordsMustMatch: t`Passwords must match`,
    thePasswordMustBeAtLeast8CharactersLong: t`The password must be at least 8 characters long`,
    firstName: t`First name`,
    surname: t`Surname`,
    email: t`Email`,
    password: t`Password`,
    confirmPassword: t`Confirm password`,
    createUser: t`Create user`,
    userInformation: t`User information`
  },

  profileUpdatedSuccessfully: t`Profile updated successfully`,
  firstName: t`First name`,
  lastName: t`Last name`,
  phoneNumber: t`Phone number`,
  save: t`Save`,
  email: t`Email`,
  required: t`Required`,
  table: {
    rowsPerPage: t`Rows per page`,
    of: t`of`,
    selected: t`selected`,
    clearFilters: t`Clear filters`
  },
  orderSummary: {
    total: t`Total`,
    discount: t`Discount`,
    VAT: t`VAT`,
    yourOrder: t`Your order`,
    vat: t`VAT`,
    other: t`Other`
  },
  errorPage: {
    title: t`We're sorry, an error has occurred`,
    description: t`We are working on solving the issue, please try again later`,
    tryAgain: t`Try again`
  },
  timePicker: {
    today: t`Today`,
    tomorrow: t`Tomorrow`,
    soldOut: t`Sold out`,
    nextButton: t`Later`,
    prevButton: t`Earlier`,
    full: t`Full`
  },

  cannotAddDateThatAlreadyHasExistingAvailability: t`Cannot add date that already has existing availability. Contact your supervisor`,
  dateAlreadyInListRemoveExistingDateFromList: t`Date already in list, remove existing date from list`,

  yourOrder: t`Your order`,
  discounts: t`Discounts`,
  other: t`Other`,
  VAT: t`VAT`,
  total: t`Total`
});

export default getCommonTranslations;
