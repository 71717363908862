import { cn } from 'noddi-ui/src/helpers/utilts';
import { PropsWithChildren } from 'react';

type NoddiCardWrapperProps = {
  className?: string;
};

export const NoddiBasicCard = ({ children, className }: PropsWithChildren<NoddiCardWrapperProps>) => {
  return <div className={cn('rounded-2xl bg-primary-white px-5 py-4 shadow-sm', className)}>{children}</div>;
};
