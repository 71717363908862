import { Trans, t } from '@lingui/macro';
import { Stack, useMediaQuery } from '@mui/material';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { LoadingScreen, NoddiButton, NoddiContainer } from 'noddi-ui';
import { useEffectAfterMount } from 'noddi-util/src/hooks';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import appRoutes from '../../../../appRoutes';
import { useTireHotelContext } from '../context';
import useStepContent from '../hooks/useStepContent';
import { TireStorageBookingSteps } from '../tireStorageBookingSteps';

const TireHotelBookingFlowWrapper = () => {
  const { currentUserGroupId } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { currentStep } = useTireHotelContext();
  const isMobile = useMediaQuery('(max-width:600px)');

  const navigate = useNavigate();

  useEffectAfterMount(() => {
    navigate(appRoutes.homepage.getBasePath());
  }, [currentUserGroupId]);

  const { data: membershipData, isPending: isMembershipsPending } = noddiAsync.useGet({
    type: URLKeys.getMembershipPrograms,
    input: { userGroupId: currentUserGroupId as number },
    queryConfig: {
      enabled: !!currentUserGroupId,
      staleTime: Infinity
    }
  });

  const stepContent = useStepContent({
    setIsLoading,
    membershipData
  });

  const currentStepContent = stepContent[currentStep];

  const onNextClicked = currentStepContent?.onNextClicked;
  const confirmText = currentStepContent?.confirmText;
  const back = currentStepContent?.back;
  const nextDisabled = currentStepContent?.nextDisabled;

  const onClose = () => {
    if (back) {
      back();
    } else {
      history.back();
    }
  };

  const showBottomBar = currentStep !== TireStorageBookingSteps.timePicker;

  if (isMembershipsPending) {
    return <LoadingScreen />;
  }

  const isTimePickerStep = currentStep === TireStorageBookingSteps.timePicker;

  return (
    <div>
      <NoddiContainer>
        <Stack maxWidth={isTimePickerStep ? 'lg' : 'sm'} sx={{ marginLeft: 'auto', marginRight: 'auto' }}>
          <h1 className='mb-4 mt-6 text-7 sm:mt-4 sm:text-13'>{currentStepContent.title}</h1>
          {currentStepContent.renderComponent(isMobile)}

          {showBottomBar && (
            <StyledBottomBarStack numberOfButtons={2} isMobile={isMobile}>
              <NoddiButton onClick={onClose} variant='secondary' disabled={isLoading}>
                {t`Back`}
              </NoddiButton>

              <NoddiButton
                onClick={async () => {
                  onNextClicked();
                }}
                variant='primary'
                disabled={isLoading || Boolean(nextDisabled?.())}
                loading={isLoading}
              >
                {confirmText ?? <Trans>Yes</Trans>}
              </NoddiButton>
            </StyledBottomBarStack>
          )}
        </Stack>
      </NoddiContainer>
    </div>
  );
};

export default TireHotelBookingFlowWrapper;

const StyledBottomBarStack = styled.div<{ numberOfButtons: number; isMobile: boolean }>`
  position: static; /* Initially set to static */
  display: flex;
  gap: 12px;
  margin-top: 24px;
  width: 100%;
  justify-content: ${(props) => (props.isMobile ? 'center' : 'flex-end')};

  @media (max-width: 600px) {
    margin-top: 0px;
    margin-bottom: 0px;
    position: fixed; /* Change to fixed on small screens (up to 600px wide) */
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000; /* Adjust z-index as needed */
    background-color: #ffffff; /* Optional: Background color */
    padding: 24px 0px; /* Optional: Padding */
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1); /* Optional: Box shadow for visual separation */
    display: flex;
    justify-content: center;

    ${(props) =>
      props.numberOfButtons === 1
        ? `
      button {
        width: 90%;
        }
    `
        : `
      button {
        width: 40%;
      }
      `}
  }
`;
