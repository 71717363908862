import { Typography } from '@mui/material';
import { NoddiCollapseCard, colors } from 'noddi-ui';

import { useBookingContext } from '../../contexts/BookingContext';

const PublicServiceAnnouncement = () => {
  const { bookingInputData } = useBookingContext();

  const serviceAreaAnnouncements = bookingInputData.serviceAreas
    .map((serviceArea) => serviceArea.publicAnnouncement)
    .filter((x) => x);

  return serviceAreaAnnouncements.length > 0 ? (
    <NoddiCollapseCard
      backgroundColor={colors.primary.darkPurple30}
      sx={{ marginBottom: '2rem' }}
      header={<Typography variant='h6'>Viktig informasjon om området ditt</Typography>}
      collapseBody={
        <ul>
          {serviceAreaAnnouncements.map((announcement, index) => (
            <li key={index}>{announcement}</li>
          ))}
        </ul>
      }
    />
  ) : null;
};

export default PublicServiceAnnouncement;
