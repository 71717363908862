import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { NoddiLinearProgressLoader } from 'noddi-ui';

import AddMoreCarsView from './AddMoreCars';

const CarSelector = () => {
  const { currentUserGroupId: userGroupId } = useAuthContext();

  const { isLoading, data: registeredCars } = noddiAsync.useGet({
    type: URLKeys.getUserGroupCarsForBooking,
    input: { userGroupId },
    queryConfig: {
      enabled: !!userGroupId
    }
  });

  if (isLoading) {
    return <NoddiLinearProgressLoader />;
  }

  return <AddMoreCarsView registeredCars={registeredCars} />;
};

export default CarSelector;
