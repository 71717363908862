import { t } from '@lingui/macro';
import * as Sentry from '@sentry/react';
import { URLKeys, noddiAsync } from 'noddi-async';
import { LoadingScreen } from 'noddi-ui';
import { useState } from 'react';

import { useBookingContext } from '../../../../contexts/BookingContext';
import BookingScreen from '../../BookingScreen';
import { useMembershipPrograms } from '../../hooks/useMembershipPrograms';
import AddonPicker from './AddonPicker';
import useOnNextButtonClick from './useOnNextButtonClick';

const Addons = () => {
  const [onNextButtonIsLoading, setOnNextButtonIsLoading] = useState(false);
  const { navigateToNextStep } = useOnNextButtonClick();

  // prefetches membership programs
  useMembershipPrograms();

  return (
    <BookingScreen
      onNextClicked={() => {
        navigateToNextStep({ setOnNextButtonIsLoading });
      }}
      title={t`Do you need help with anything else?`}
    >
      {onNextButtonIsLoading ? <LoadingScreen /> : <AddonsSelector />}
    </BookingScreen>
  );
};

export const AddonsSelector = () => {
  const {
    bookingInputData: { selectedCars },
    salesItems
  } = useBookingContext();
  const salesItemsWithoutAddons = salesItems.filter((item) => item.isAddon === false);

  const inputs = selectedCars.map((car) => ({
    type: URLKeys.getAddonsForBooking,
    input: { carSizeIds: [car.carSizeId], salesItemIds: salesItemsWithoutAddons.map((item) => item.id) },
    queryConfig: {
      staleTime: Infinity
    }
  }));

  const results = noddiAsync.useGetAll(inputs);

  const isLoading = results.map((result) => result.isPending).some((loading) => loading);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className='flex flex-col gap-10'>
      {selectedCars.map((car, index) => {
        const addonsForCar = results[index]?.data;
        const queryParams = inputs[index]?.input;

        if (!addonsForCar || !queryParams) {
          return Sentry.captureMessage(
            'No addons or queryParams found for car in the results array, should never happen',
            {
              extra: {
                car
              }
            }
          );
        }

        return (
          <div key={car.licensePlateNumber}>
            <AddonPicker
              car={car}
              addons={addonsForCar}
              queryParams={{ queryInput: queryParams, urlKey: URLKeys.getAddonsForBooking }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Addons;
