import { Trans, t } from '@lingui/macro';
import { NoddiBasicCard, NoddiButton, NoddiDialog, useIsMobile } from 'noddi-ui';
import { cn } from 'noddi-ui/src/helpers/utilts';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TimeWindowPicker from '../../../../components/BookingFlow/TimeWindowPicker';
import { ErrorPageWithTranslations } from '../../../../components/ErrorPageWithTrans';
import { useBookingContext } from '../../../../contexts/BookingContext';
import BookingScreen from '../../BookingScreen';
import { addressStepPath, summaryStepPath } from '../../bookingFlowPaths';

const TimeWindowLayout = () => {
  const { bookingInputData: inputData } = useBookingContext();

  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [showExtraInfoModal, setShowExtraInfoModal] = useState(false);

  if (!inputData.serviceAreaId) {
    return (
      <ErrorPageWithTranslations
        errorMessage={t`It looks like there is an configuration error in your area. Please contact us at hei@noddi.no and provide us with your address and selected services, and we will solve this for you as fast as possible`}
        customCallback={{
          ctaText: t`Start new booking`,
          callback: () => navigate(addressStepPath)
        }}
      />
    );
  }

  return (
    <BookingScreen title={t`When is it convenient for us to come to you?`} noMaxwidth>
      <NoddiBasicCard
        className={cn('mb-4 h-fit p-8 max-sm:p-0', isMobile && 'bg-systemColors-transparent shadow-none')}
      >
        <div className='-ml-3 mb-4 flex w-full justify-start sm:mb-0 sm:justify-end'>
          <NoddiButton startIcon='ArrowSquareRightCorner' onClick={() => setShowExtraInfoModal(true)} variant='link'>
            <Trans>Read more about delivery</Trans>
          </NoddiButton>
        </div>
        <TimeWindowPicker onNextClicked={() => navigate(summaryStepPath)} />
      </NoddiBasicCard>

      <NoddiDialog title={t`Delivery`} onClose={() => setShowExtraInfoModal(false)} open={showExtraInfoModal}>
        <div className='flex flex-col gap-4'>
          <Trans>
            We’ll send you an SMS the day before your scheduled booking with an estimated time of arrival. This will be
            based on our finalized route for the technicians, giving you a better idea of when to expect us.
            Additionally, we’ll send you another shortly before we arrive.
          </Trans>
          <div>
            <p className='font-bold'>
              <Trans>Price</Trans>
            </p>
            <Trans>
              Most of our time slots are free, but for shorter, more specific time slots, there is a small additional
              charge. This is due to the added limitations these time slots create for our scheduling.
            </Trans>
          </div>
        </div>
      </NoddiDialog>
    </BookingScreen>
  );
};

export default TimeWindowLayout;
