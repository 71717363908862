import { Trans, t } from '@lingui/macro';

import ContentWrapper from '../../../components/Layouts/ContentWrapper';

const NoBookingDataFound = () => {
  return (
    <ContentWrapper title={t`Your booking`}>
      <p>
        <Trans>No data found for your booking. Please contact us if the problem persist.</Trans>
      </p>
    </ContentWrapper>
  );
};

export default NoBookingDataFound;
