export const wheelStorageOption = {
  customer: 'customer',
  carDealer: 'carDealer',
  wheelStorage: 'wheelStorage'
} as const;

export type WheelStorageOptionType = keyof typeof wheelStorageOption;

export type MarkWheelStoragePickupAsComplete = {
  bookingItemId: number;
  deliveredAt: string;
  carWheelSetTypeId: number;
  storageAreaId: number;
  storageUnitId: number;
  storageFacilityId: number;
};
