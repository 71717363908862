import { NavigateFunction } from 'react-router-dom';

import routes from '../appRoutes';
import { CONFIRMATION_TYPES } from '../pages/Confirmation/types';

const routeToConfirmation = ({ navigate, id }: { id: number | null; navigate: NavigateFunction }) => {
  const queryParams = new URLSearchParams({
    type: CONFIRMATION_TYPES.booking,
    createdResourceId: id?.toString() ?? ''
  });

  navigate(routes.confirmation.getPath({ params: queryParams.toString() }));
};

export { routeToConfirmation };
