import { emailRegex } from 'noddi-util';
import * as yup from 'yup';

type Translations = {
  thisFieldIsRequired: string;
  thisFieldMustBeAtLeast2CharactersLong: string;
  mustProvideAValidEmailAddress: string;
  passwordsMustMatch: string;
  thePasswordMustBeAtLeast8CharactersLong: string;
};

const userRegistrationFormSchema = (skipPasswordCreation: boolean, translations: Translations) =>
  yup.object().shape({
    firstName: yup
      .string()
      .required(translations.thisFieldIsRequired)
      .min(2, translations.thisFieldMustBeAtLeast2CharactersLong),
    surname: yup
      .string()
      .required(translations.thisFieldIsRequired)
      .min(2, translations.thisFieldMustBeAtLeast2CharactersLong),
    email: yup
      .string()
      .required(translations.thisFieldIsRequired)
      .email(translations.mustProvideAValidEmailAddress)
      .matches(emailRegex, translations.mustProvideAValidEmailAddress),
    password: skipPasswordCreation
      ? yup.string().notRequired()
      : yup
          .string()
          .required(translations.thisFieldIsRequired)
          .min(8, translations.thePasswordMustBeAtLeast8CharactersLong),
    confirmPassword: skipPasswordCreation
      ? yup.string().notRequired()
      : yup
          .string()
          .required(translations.thisFieldIsRequired)
          .oneOf([yup.ref('password')], translations.passwordsMustMatch)
  });

export default userRegistrationFormSchema;
