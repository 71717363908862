import { AddressProps } from 'noddi-async/src/types';
import { NoddiBasicCard, NoddiFeedbackBox, useIsMobile, useScrollIntoView } from 'noddi-ui';
import { useState } from 'react';
import NoServiceOfferedForm from './NoServiceOfferedForm';

type SelectedAddressDetailsProps = {
  address: AddressProps;
  navigateToNextStepWhenAvailableService?: () => void;
  translations: {
    noServiceOfferedNotice: string;
  };
};

const NoServiceOffered = ({ translations, address }: SelectedAddressDetailsProps) => {
  const isMobile = useIsMobile();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const containerRef = useScrollIntoView(!!address && isMobile);

  if (isFormSubmitted) {
    return (
      <div className='mt-4'>
        <NoddiFeedbackBox variant='success' heading='Thank you! We’ll Keep You Updated' />
      </div>
    );
  }
  return (
    <div ref={containerRef} className='mt-4'>
      <NoddiBasicCard>
        <div>{translations.noServiceOfferedNotice} 😔</div>

        <div className='mt-4'>
          <NoServiceOfferedForm address={address} setIsFormSubmitted={setIsFormSubmitted} />
        </div>
      </NoddiBasicCard>
    </div>
  );
};

export default NoServiceOffered;
