import { URLKeys } from 'noddi-async';
import { AddressProps } from 'noddi-async/src/types';
import { getCachedDataFromExactMatch } from 'noddi-async/src/utils';
import { useNavigate } from 'react-router-dom';

import routes from '../../../../appRoutes';
import { useBookingContext } from '../../../../contexts/BookingContext';
import { GoogleTrackingService } from '../../../../services/GoogleTrackingService';
import { GtmEvents } from '../../../../types/gtmTracking';

const useOnNextButtonClick = () => {
  const navigate = useNavigate();
  const { bookingInputData, resetSalesItems, resetTimeWindows } = useBookingContext();
  const { selectedCars, address } = bookingInputData;

  const trackViewItem = () => {
    const selectedServiceAreaFromCoordinate = getCachedDataFromExactMatch({
      urlKey: URLKeys.getServiceAreasFromCoordinate,
      input: {
        lat: address?.latitude,
        lng: address?.longitude
      }
    });

    if (!selectedServiceAreaFromCoordinate) {
      return;
    }

    GoogleTrackingService.trackEvent({
      eventType: GtmEvents.viewItem,
      data: {
        address: address as AddressProps,
        serviceArea: selectedServiceAreaFromCoordinate
      }
    });
  };

  const navigateToNextStep = () => {
    // if we change the address after choosing a car and the service time, we must reset salesItems on cars and timeWindows
    // if we are editing a booking, we don't want to reset salesItems of course
    if (selectedCars.length > 0) {
      resetSalesItems();
      resetTimeWindows();
    }

    trackViewItem();

    navigate(routes.newBookingCarStep.getPath());
  };

  return { navigateToNextStep };
};

export default useOnNextButtonClick;
