import { t } from '@lingui/macro';

export const getServiceCategoryTranslations = ({ serviceCategoryName }: { serviceCategoryName: string }) => {
  if (serviceCategoryName === 'Wheel change') {
    return {
      name: t`Wheel change`
    };
  } else if (serviceCategoryName === 'Car wash') {
    return {
      name: t`Car wash`
    };
  } else if (serviceCategoryName === 'Wheel storage') {
    return {
      name: t`Wheel storage`
    };
  }

  return null;
};

export function getErrorCodes() {
  return {
    invalid_phone_number: t`invalid_phone_number`,
    invalid_verification_code: t`invalid_verification_code`,
    invalid_code: t`invalid_code`,
    user_not_naf_member: t`user_not_naf_member`,
    new_user_not_eligible_for_campaign: t`new_user_not_eligible_for_campaign`,
    booking_time_window_not_bookable: t`booking_time_window_not_bookable`,
    user_phone_or_email_already_exists: t`user_phone_or_email_already_exists`,
    capacity_contribution_already_exists: t`capacity_contribution_already_exists`,
    car_not_found: t`car_not_found`,
    credentials_invalid: t`credentials_invalid`,
    phone_number_verification_error: t`phone_number_verification_error`,
    pin_code_verification_error: t`pin_code_verification_error`,
    password_verification_failed: t`password_verification_failed`,
    genericErrorMessage: t`genericErrorMessage`
  } as const;
}
