import { Trans } from '@lingui/macro';
import { Divider, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { MembershipProgramProps } from 'noddi-async/src/types';
import { KeyValueRow } from 'noddi-ui';
import { formatCurrencyAmount } from 'noddi-util/src/currency';

import { useBookingContext } from '../../../contexts/BookingContext';
import useBookingSummaryProps from '../../../hooks/useBookingSummaryProps';
import { CarSummary } from './Car';
import { CouponsSummary } from './Coupons';
import DeliveryCostSection from './DeliveryCost';
import { DiscountSummary } from './Discount';

type BookingSummaryProps = {
  membershipData?: MembershipProgramProps[] | undefined;
};

const BookingSummary = ({ membershipData }: BookingSummaryProps) => {
  const { coupons, discounts, totalPrice } = useBookingSummaryProps({ membershipData });
  const { bookingInputData, salesItems } = useBookingContext();
  const vatRate = 0.2;

  const hasRelevantActiveCoupons = coupons.some((x) =>
    x.validForSalesItemIds.some((y) => salesItems.map((z) => z.id).includes(y))
  );

  return (
    <Stack width='100%'>
      {bookingInputData.selectedCars.map((car) => (
        <CarSummary key={car.licensePlateNumber} car={car} />
      ))}
      {discounts.length > 0 && <DiscountSummary discounts={discounts} membershipData={membershipData} />}
      {hasRelevantActiveCoupons && <CouponsSummary activeCoupons={coupons} />}
      {bookingInputData.time && <DeliveryCostSection timeWindow={bookingInputData.time} />}

      <Divider sx={{ marginTop: '1.5em', marginBottom: '0.5em' }} />

      <KeyValueRow
        sx={{ marginTop: '0.3em' }}
        value={
          <Typography padding={0} fontStyle='italic'>
            {formatCurrencyAmount(totalPrice * vatRate)}
          </Typography>
        }
        header={
          <Typography padding={0} fontStyle='italic'>
            <Trans>VAT</Trans>
          </Typography>
        }
        showDivider={false}
      />
      <KeyValueRow
        sx={{ marginTop: '0.3em' }}
        value={
          <Typography padding={0} fontWeight={700}>
            {formatCurrencyAmount(totalPrice)}
          </Typography>
        }
        header={
          <Typography padding={0} fontWeight={700}>
            Total
          </Typography>
        }
        showDivider={false}
      />
    </Stack>
  );
};

export default BookingSummary;
