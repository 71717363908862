import { ErrorPage, ErrorPageProps } from 'noddi-ui';

import getCommonTranslations from '../commonTranslations';
import { getErrorCodes } from '../utils/translation';

const ErrorPageWithTranslations = ({ ...rest }: Omit<ErrorPageProps, 'translations'>) => {
  return <ErrorPage {...rest} translations={getCommonTranslations().errorPage} errorCodes={getErrorCodes()} />;
};

export { ErrorPageWithTranslations };
