import { css } from 'styled-components';

const CardCommonCss = css`
  background-color: #f7f6fa;
  padding: 10px;
  border-radius: 12px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  border: 2px solid transparent;
  min-height: 110px;
`;

export { CardCommonCss };
