import { Trans } from '@lingui/macro';
import { NoddiExternalLink } from 'noddi-ui';
import { CUSTOMER_SERVICE_EMAIL } from '../constants/externalUrls';

export const ContactUs = ({ customText }: { customText?: string }) => {
  return (
    <span>
      <Trans>Feel free to reach out to us in the chat or at</Trans>{' '}
      <NoddiExternalLink className='p-0' href={CUSTOMER_SERVICE_EMAIL}>
        hei@noddi.no
      </NoddiExternalLink>{' '}
      {customText ?? <Trans>if you have any questions</Trans>}
    </span>
  );
};
