import { Trans } from '@lingui/macro';
import { NoddiBasicCard } from 'noddi-ui';

import { ContactUs } from '../../components/ContactUs';

const NoCoupons = () => {
  return (
    <NoddiBasicCard className='flex flex-col gap-5'>
      <p className='font-bold text-5'>
        <Trans>No coupons found</Trans> 😭
      </p>

      <ContactUs />
    </NoddiBasicCard>
  );
};
export default NoCoupons;
