import { t, Trans } from '@lingui/macro';
import { ServerTypes } from 'noddi-async';
import { NoddiDialog } from 'noddi-ui';

import { ContactUs } from '../../../../components/ContactUs';
import { useBookingContext } from '../../../../contexts/BookingContext';

interface IncompatibleDialogProps {
  open: boolean;
  onClose: () => void;
  salesItem: ServerTypes.AvailableSalesItem;
}

export const IncompatibleDialog = ({ open, onClose, salesItem }: IncompatibleDialogProps) => {
  const { incompatibleServiceCategories, getAllAvailableSalesItemsOrUndefined } = useBookingContext();

  const incompatibleServiceCategoryIds = incompatibleServiceCategories.flatMap(
    (category) => category.incompatibleCategoriesIds
  );

  const incompatibleSalesItemNames =
    getAllAvailableSalesItemsOrUndefined()
      ?.filter((item) => incompatibleServiceCategoryIds.includes(item.serviceCategoryId))
      .map((item) => item.name) ?? [];

  const incompatibles = Array.from(new Set(incompatibleSalesItemNames));

  return (
    <NoddiDialog title={t`Unavailable combination`} onClose={onClose} open={open}>
      <div className='flex flex-col gap-4'>
        <p>
          <Trans>
            <span className='font-bold'>{salesItem.name}</span> is currently not offered together with the following
            services:
          </Trans>
        </p>
        <div className='flex flex-col gap-2'>
          <ul>
            {incompatibles.map((item: string) => (
              <li key={item}>
                <p className='font-bold'>{item}</p>
              </li>
            ))}
          </ul>
        </div>
        <p>
          <Trans>To book this combination, please create two separate bookings.</Trans> <ContactUs />
        </p>
      </div>
    </NoddiDialog>
  );
};
