import { ApiErrorMessage } from 'noddi-ui';
import { ApiErrorMessageProps } from 'noddi-ui/src/components/Elements/Errors/ErrorMessage';

import { getErrorCodes } from '../utils/translation';

const ApiErrorMessageWithTrans = ({ ...rest }: ApiErrorMessageProps) => {
  return <ApiErrorMessage {...rest} errorCodes={getErrorCodes()} />;
};

export { ApiErrorMessageWithTrans };
