import { t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { MembershipProgramProps } from 'noddi-async/src/types';
import { NoddiBasicCard, NoddiButton } from 'noddi-ui';
import { cn } from 'noddi-ui/src/helpers/utilts';
import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';

import AddressPickerComponent from '../../../../components/BookingFlow/AddressPickerComponent';
import TimeWindowPicker from '../../../../components/BookingFlow/TimeWindowPicker';
import { useBookingContext } from '../../../../contexts/BookingContext';
import { routeToConfirmation } from '../../../../helper/confirmation';
import useBookingPayload from '../../../../hooks/useBookingPayload';
import useCleanupBooking from '../../../../hooks/useCleanupBooking';
import { useStepCompletionValidation } from '../../../BookingFlow/hooks/useStepCompletionValidation';
import useUpdateSalesItemIncompatibles from '../../../BookingFlow/hooks/useUpdateCompatibleServiceAreas';
import ConfirmBooking from '../Components/Steps/CofirmBooking';
import ReturnChoices from '../Components/Steps/ReturnChoices';
import { useTireHotelContext } from '../context';
import { TireStorageBookingSteps } from '../tireStorageBookingSteps';

type Props = {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  membershipData: MembershipProgramProps[] | undefined;
};
const useStepContent = ({ setIsLoading, membershipData }: Props) => {
  const { updateSalesItemIncompatibles } = useUpdateSalesItemIncompatibles();
  const { salesItems } = useBookingContext();
  const { createBookingPayload } = useBookingPayload();
  const { cleanupBookingData } = useCleanupBooking();
  const { isAddressStepValid, isTimeWindowsStepValid, isSalesItemStepValid } = useStepCompletionValidation();
  const navigate = useNavigate();
  const { setCurrentStep, setDialogOpen } = useTireHotelContext();

  const { mutateAsync: createBooking } = noddiAsync.usePost({
    type: URLKeys.postBooking,
    queryConfig: {
      onSuccess: async (data) => {
        cleanupBookingData();
        routeToConfirmation({ navigate, id: data.data.id });
      }
    }
  });

  // fetch salesItems when actions

  type StepContent = {
    [key in TireStorageBookingSteps]: {
      confirmText: string;
      onNextClicked: () => void;
      renderComponent: (isMobile: boolean) => JSX.Element;
      back?: () => void;
      nextDisabled?: () => boolean;
      title: string;
    };
  };

  const stepContent: StepContent = {
    [TireStorageBookingSteps.returnChoices]: {
      confirmText: t`Next`,
      title: t`How you want to return your tires?`,
      onNextClicked: () => {
        if (salesItems.length === 0) {
          // TODO ser error message
          return;
        }

        // If the user has selected to pick up the tires themselves, we skip the address step
        // and instead use the address of the serviceContract

        // TODO: fix pickUp yourself
        // const isPickUpYourself = selectedSalesItems.some(
        //   (item) => item.slug === SalesItemSlugs['nb-tire-storage-pickup-yourself']
        // );

        // if (isPickUpYourself) {
        //   // since we potentially have multiple serviceContracts, there is no nice way to
        //   // really decide which address to use, so we simply use the first one
        //   if (selectedTireHotelContracts.length < 1) {
        //     throw new Error('No serviceContracts found');
        //   }

        //   const first = selectedTireHotelContracts[0];
        //   if (first?.address) {
        //     updateBookingInputData({ address: first.address });
        //   }
        //   return setCurrentStep(TireStorageBookingSteps.confirm);
        // }
        return setCurrentStep(TireStorageBookingSteps.address);
      },
      back: () => {
        navigate(-1);
      },
      nextDisabled: () => !isSalesItemStepValid,
      renderComponent: () => <ReturnChoices />
    },
    [TireStorageBookingSteps.address]: {
      title: t`Where is your car parked?`,
      confirmText: t`Next`,
      onNextClicked: async () => {
        updateSalesItemIncompatibles(salesItems);
        setCurrentStep(TireStorageBookingSteps.timePicker);
      },
      renderComponent: () => <AddressPickerComponent hideServiceInfoBox />,

      back: () => {
        setCurrentStep(TireStorageBookingSteps.returnChoices);
      },
      nextDisabled: () => !isAddressStepValid
    },
    [TireStorageBookingSteps.timePicker]: {
      title: t`When is it convenient for us to come to you?`,
      confirmText: t`Next`,
      onNextClicked: () => {
        setCurrentStep(TireStorageBookingSteps.confirm);
      },
      renderComponent: (isMobile?: boolean) => (
        <>
          <div className='-mx-4'>
            <NoddiButton
              onClick={() => {
                setCurrentStep(TireStorageBookingSteps.address);
              }}
              variant='ghost'
              startIcon='ArrowLeft'
            >
              {t`Back`}
            </NoddiButton>
          </div>

          <NoddiBasicCard
            className={cn('mb-4 h-fit p-8 max-sm:p-0', isMobile && 'bg-systemColors-transparent shadow-none')}
          >
            <TimeWindowPicker onNextClicked={() => setCurrentStep(TireStorageBookingSteps.confirm)} />
          </NoddiBasicCard>
        </>
      ),
      back: () => {
        setCurrentStep(TireStorageBookingSteps.address);
      },
      nextDisabled: () => !isTimeWindowsStepValid
    },
    [TireStorageBookingSteps.confirm]: {
      title: t`Does everything look good?`,
      confirmText: t`Confirm`,
      onNextClicked: async () => {
        const payload = createBookingPayload(membershipData);

        setIsLoading(true);
        await createBooking(payload);
        setIsLoading(false);
        setDialogOpen(false);
      },
      renderComponent: () => <ConfirmBooking membershipData={membershipData} />,
      back: () => {
        setCurrentStep(TireStorageBookingSteps.timePicker);
      }
    }
  };

  return stepContent;
};

export default useStepContent;
