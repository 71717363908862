import { hasStreetNumber } from 'noddi-ui';
import { useBookingContext } from '../../../contexts/BookingContext';
import { useWheelStore } from '../../../stores/WheelStore';
import { SelectedCar, SelectedSalesItem } from '../interfaces';

const allCarsHaveAtLeastOneSalesItem = ({
  cars,
  salesItems
}: {
  cars: SelectedCar[];
  salesItems: SelectedSalesItem[];
}) => {
  return cars.every((car) => salesItems.map((item) => item.licensePlateNumber).includes(car.licensePlateNumber));
};

export const useStepCompletionValidation = () => {
  const { salesItems, bookingInputData } = useBookingContext();
  const { selectedWheelStorageOptions, getWheelStorageItems } = useWheelStore();
  const { address, serviceCategories, time, selectedCars } = bookingInputData;

  const wheelStorageStepCheck = () => {
    const wheelStorageSalesItems = getWheelStorageItems(salesItems);
    const vehiclesWithWheelStorage = [...new Set(wheelStorageSalesItems.map((item) => item.licensePlateNumber))];

    const allHaveSelectedState = vehiclesWithWheelStorage.every((licensePlateNumber) => {
      const selectedOptions = selectedWheelStorageOptions.find(
        (option) => option.licensePlateNumber === licensePlateNumber
      );
      if (!selectedOptions) {
        return false;
      }
      const { pickUpAddress, storageType } = selectedOptions;

      return pickUpAddress && storageType;
    });

    return allHaveSelectedState;
  };

  const isAddressStepValid = !!serviceCategories?.length && hasStreetNumber(address);

  const isCarStepValid = selectedCars.length > 0;

  const isSalesItemStepValid = allCarsHaveAtLeastOneSalesItem({ cars: selectedCars, salesItems });

  const isTimeWindowsStepValid = time?.id;

  const isWheelStorageStepValid = wheelStorageStepCheck();

  return { isAddressStepValid, isCarStepValid, isSalesItemStepValid, isWheelStorageStepValid, isTimeWindowsStepValid };
};
