import { t, Trans } from '@lingui/macro';
import { Form, Formik } from 'formik';
import { noddiAsync, URLKeys } from 'noddi-async';
import { ApiErrorMessage, LoadingScreen, NoddiButton, NoddiCollapseCard, NoddiFormAutocomplete } from 'noddi-ui';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import routes from '../../appRoutes';
import TireSizeGuideImage from '../../assets/images/tireGuide.png';
import { ApiErrorMessageWithTrans } from '../../components/ApiErrorMessageWithTrans';

const validationSchema = Yup.object().shape({
  tireDimensions: Yup.object({
    front: Yup.string(),
    rear: Yup.string()
  })
});

type FormValues = Yup.InferType<typeof validationSchema>;

function getDimensionOptions(dimensionSearch: string, carWheelSetsDimensions: string[]) {
  const carWheelSetsDimensionsOptions = carWheelSetsDimensions.map((dimension) => ({
    value: dimension,
    label: dimension
  }));

  return carWheelSetsDimensionsOptions.filter((option) =>
    option.label.toLowerCase().includes(dimensionSearch.toLowerCase())
  );
}

interface TireDimensionUpdateProps {
  id: number;
}

const TireDimensionUpdate = ({ id }: TireDimensionUpdateProps) => {
  const [dimensionSearch, setDimensionSearch] = useState('');
  const [dimensionRearSearch, setDimensionRearSearch] = useState('');
  const [dimensionFrontSearch, setDimensionFrontSearch] = useState('');
  const [staggeredWheels, setStaggeredWheels] = useState(false);
  const navigate = useNavigate();
  const {
    data: carWheelSetsDimensions,
    isPending: isCarWheelSetsDimensionsPending,
    error: carWheelSetsDimensionsError
  } = noddiAsync.useGet({
    type: URLKeys.getCarWheelSetsDimensions
  });

  const {
    mutateAsync: postRejectTireOfferQuote,
    isPending: isPostRejectTireOfferQuotePending,
    error: postRejectTireOfferQuoteError
  } = noddiAsync.usePost({
    type: URLKeys.postRejectTireOfferQuote,
    queryConfig: {
      onSuccess: () => {
        navigate(routes.tireOfferConfirmation.getPath({ action: 'declined' }));
      }
    }
  });

  if (isCarWheelSetsDimensionsPending) {
    return <LoadingScreen />;
  }

  if (carWheelSetsDimensionsError) {
    return <ApiErrorMessage error={carWheelSetsDimensionsError} />;
  }

  return (
    <div className='mb-4 flex flex-col gap-8'>
      <Formik<FormValues>
        initialValues={{
          tireDimensions: { front: '', rear: '' }
        }}
        validationSchema={validationSchema}
        validateOnMount
        //@ts-ignore
        onSubmit={() => {}}
      >
        {({ values, setFieldValue }) => (
          <Form>
            {staggeredWheels ? (
              <div className='flex flex-col gap-4'>
                <NoddiFormAutocomplete
                  label={t`Select front tire dimension`}
                  name='tireDimensions.front'
                  placeholder={t`Search for tire dimensions`}
                  options={getDimensionOptions(dimensionFrontSearch, carWheelSetsDimensions)}
                  defaultOption={undefined}
                  onInputChange={(inputValue) => {
                    setDimensionFrontSearch(inputValue);
                  }}
                  onChange={(option) => {
                    setFieldValue('tireDimensions.front', option?.value);
                  }}
                />

                <NoddiFormAutocomplete
                  label={t`Select rear tire dimension`}
                  name='tireDimensions.rear'
                  placeholder={t`Search for tire dimensions`}
                  options={getDimensionOptions(dimensionRearSearch, carWheelSetsDimensions)}
                  defaultOption={undefined}
                  onInputChange={(inputValue) => {
                    setDimensionRearSearch(inputValue);
                  }}
                  onChange={(option) => {
                    setFieldValue('tireDimensions.rear', option?.value);
                  }}
                />
              </div>
            ) : (
              <NoddiFormAutocomplete
                label={t`Select tire dimension`}
                name='tireDimensions'
                placeholder={t`Search for tire dimensions`}
                options={getDimensionOptions(dimensionSearch, carWheelSetsDimensions)}
                defaultOption={undefined}
                onChange={(option) => {
                  setFieldValue('tireDimensions.front', option?.value);
                  setFieldValue('tireDimensions.rear', option?.value);
                }}
                onInputChange={(inputValue) => {
                  setDimensionSearch(inputValue);
                }}
              />
            )}
            {!staggeredWheels && (
              <NoddiButton variant='link' className='text-wrap pl-0' onClick={() => setStaggeredWheels(true)}>
                <Trans>Different tire dimensions on front and rear tires?</Trans>
              </NoddiButton>
            )}

            {postRejectTireOfferQuoteError && <ApiErrorMessageWithTrans error={postRejectTireOfferQuoteError} />}
            {values.tireDimensions.front && values.tireDimensions.rear && (
              <div className='flex justify-end'>
                <NoddiButton
                  onClick={async () =>
                    await postRejectTireOfferQuote({
                      comments: `NEW OFFER REQUEST: with tire dimensions: front tire dimensions: ${values.tireDimensions.front} - rear tire dimensions:${values.tireDimensions.rear}`,
                      tireQuoteId: id
                    })
                  }
                  loading={isPostRejectTireOfferQuotePending}
                  className='mt-2'
                >
                  <Trans>Submit</Trans>
                </NoddiButton>
              </div>
            )}
          </Form>
        )}
      </Formik>
      <NoddiCollapseCard
        header={
          <p className='font-bold text-primary-darkPurple'>
            <Trans>How can I find my tire dimensions?</Trans>
          </p>
        }
        collapseBody={
          <div className='mt-4 flex flex-col gap-4'>
            <p>
              <Trans>
                You’ll usually find the tire dimensions on the side of the tire, written as a series of numbers and
                letters, e.g., 205/65R15. This indicates width, profile, and rim diameter. You can also find this
                information in the car’s manual or on a sticker inside the door frame or fuel cap.
              </Trans>
            </p>

            <img src={TireSizeGuideImage} />
          </div>
        }
        sx={{ padding: '0' }}
      />
    </div>
  );
};

export { TireDimensionUpdate };
