import { SalesItemBookingCategory } from '../../adminapp';
import { CarSize } from '../cars';
import { ServiceCategory } from '../serviceCategory';

export interface SalesItemListItem {
  id: number;
  name: string;
  price: number;
  currency: string;
  carSizes: CarSize[];
}

type CarIdInput = {
  serviceAreaIds: number[];
  carId: number;
};

type CarSizeIdInput = {
  serviceAreaIds: number[];
  carSizeId: number | undefined;
  licensePlateNumber: string;
};

export const SalesItemGroupValues = {
  'tire-storage-cancel-options': 'tire-storage-cancel-options',
  'tire-storage-renew': 'tire-storage-renew'
} as const;

export type SalesItemGroupValueTypes = keyof typeof SalesItemGroupValues;

export type SalesItemGroup = {
  group: SalesItemGroupValueTypes;
};

export type AvailableSalesItemsForBookingInput = {
  carSizeId: number | undefined;
  latitude: number;
  longitude: number;
  licensePlateNumber: string;
};

export type AvailableSalesItemsForBooking = {
  salesItems: AvailableSalesItem[];
  meta: { hasActiveTireHotelContract: boolean };
};

export type GetSalesItemForBooking =
  | CarIdInput
  | CarSizeIdInput
  | SalesItemGroup
  | {
      userGroupIds?: number[];
    };

export interface AvailableSalesItem {
  id: number;
  name: string;
  description: string;
  shortDescription: string;
  price: number;
  serviceTime: number;
  salesItemType: string;
  bookingCategory: SalesItemBookingCategory | null;
  slug: string;
  serviceCategory: ServiceCategory;
  serviceCategoryId: number;
  incompatibleIds: number[];
  addonIds: number[];
  uiSortOrder: number;
  bookingCategorySlug: string;
}

export type SalesItemOption = {
  id: number;
  name: string;
};

export const SalesItemSlugs = {
  ['nb-at-your-place-free']: 'nb-at-your-place-free',
  ['nb-pick-up-at-another-address']: 'nb-pick-up-at-another-address',
  ['nb-tire-storage-included-wheel-change-sm']: 'nb-tire-storage-included-wheel-change-sm',
  ['nb-tire-storage-included-wheel-change-lg']: 'nb-tire-storage-included-wheel-change-lg',
  ['nb-tire-storage-only-storage']: 'nb-tire-storage-only-storage',
  ['nb-tire-storage-home-delivery']: 'nb-tire-storage-home-delivery',
  ['nb-tire-storage-cancel-and-wheel-change']: 'nb-tire-storage-cancel-and-wheel-change',
  ['nb-tire-storage-pickup-yourself']: 'nb-tire-storage-pickup-yourself',
  ['nb-tire-storage-renew-tire-storage-with-wheel-change']: 'nb-tire-storage-renew-tire-storage-with-wheel-change',
  ['nb-wheel-change-sm']: 'nb-wheel-change-sm',
  ['nb-wheel-change-lg']: 'nb-wheel-change-lg'
} as const;

export type SalesItemSlugType = keyof typeof SalesItemSlugs;
