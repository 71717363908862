import { t } from '@lingui/macro';
import { useNavigate } from 'react-router-dom';

import CarSelector from '../../../../../BookingFlow/Steps/Cars/CarSelector';
import { useStepCompletionValidation } from '../../../../../BookingFlow/hooks/useStepCompletionValidation';
import ExpandBookingScreen from '../../ExpandBookingScreen';
import useExpandBookingPaths from '../../hooks/useExpandBookingPaths';

const Car = () => {
  const { isCarStepValid } = useStepCompletionValidation();
  const navigate = useNavigate();
  const { salesItemsPath } = useExpandBookingPaths();

  return (
    <ExpandBookingScreen
      hideShoppingCart
      onNextClicked={() => navigate(salesItemsPath)}
      disableNextButton={!isCarStepValid}
      title={t`Add one or more cars`}
    >
      <CarSelector />
    </ExpandBookingScreen>
  );
};

export default Car;
