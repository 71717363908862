import { TireHotelContract } from 'noddi-async/src/types';
import { useAuthContext } from 'noddi-provider';

import { useBookingContext } from '../../../../contexts/BookingContext';
import { SelectedCar } from '../../../BookingFlow/interfaces';

type Props = {
  tireHotelContracts: TireHotelContract[];
};

const usePopulateStore = () => {
  const { updateBookingInputData, resetBookingData } = useBookingContext();

  const { currentUserGroupId } = useAuthContext();

  const prePopulateSessionStorageFromTireHotelContracts = ({ tireHotelContracts }: Props) => {
    resetBookingData();
    const selectedCars = buildSelectedCarsFromTireHotelContract({
      tireHotelContracts,
      userGroupId: currentUserGroupId as number
    });

    updateBookingInputData({
      selectedCars
    });
  };

  return {
    prePopulateSessionStorageFromTireHotelContracts
  };
};
export default usePopulateStore;

const buildSelectedCarsFromTireHotelContract = ({
  tireHotelContracts
}: Props & {
  userGroupId: number;
}): SelectedCar[] => {
  return tireHotelContracts.map((contract) => {
    const car = contract.car;

    return {
      carName: `${car.make} ${car.model}`,
      licensePlateNumber: car.licensePlateNumber,
      carSizeId: car.carSizeId,
      vinNumber: car.vinNumber
    };
  });
};
