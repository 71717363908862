import { Trans } from '@lingui/macro';
import { tracking, useLanguageContext } from 'noddi-provider';
import { SMSLogin, UserRegistrationForm } from 'noddi-ui';
import getCommonTranslations from '../commonTranslations';
import { getErrorCodes } from '../utils/translation';

export const BenefitsLogin = () => {
  const { currentLanguage } = useLanguageContext();

  return (
    <div className='flex flex-col gap-2'>
      <p className='mt-5 font-bold text-5 text-primary-darkPurple'>
        <Trans>To activate your benefits, we first need to verify your phonenumber</Trans>
      </p>

      <p className='text-primary-darkPurple opacity-80'>
        <Trans>We will create a new user for you, if you do not have one</Trans>
      </p>
      <div className='text-primary-darkPurple'>
        <SMSLogin
          errorCodes={getErrorCodes()}
          translations={getCommonTranslations().smsLoginTranslations}
          language={currentLanguage}
          hidePhoneNumberText
          trackUserIdentity={(user) => {
            tracking.trackUserLogin(user);
          }}
          userRegistrationForm={(phoneNumber) => (
            <UserRegistrationForm
              errorCodes={getErrorCodes()}
              translations={getCommonTranslations().registrationTranslations}
              phoneNumber={phoneNumber}
              skipPasswordCreation
              trackUserIdentity={(user) => {
                tracking.trackUserIdentity(user);
              }}
            />
          )}
        />
      </div>
    </div>
  );
};
