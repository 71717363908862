import { noddiAsync } from 'noddi-async';

import { useBookingContext } from '../contexts/BookingContext';
import { useCouponStore } from '../stores/CouponStore';
import { useWheelStore } from '../stores/WheelStore';

const useCleanupBooking = () => {
  const { resetBookingData } = useBookingContext();
  const { reset: resetCouponStore } = useCouponStore();
  const { reset: resetWheelStorageStore } = useWheelStore();

  const cleanupBookingData = () => {
    noddiAsync.queryClient.invalidateQueries();
    resetBookingData();
    resetCouponStore();
    resetWheelStorageStore();
  };

  return {
    cleanupBookingData
  };
};

export default useCleanupBooking;
