import { Trans, t } from '@lingui/macro';

import { WheelChangeImageCard } from '../../components/Home/WheelChangeImageCard';

export const UserAlreadyAMember = () => {
  return (
    <div className='flex flex-col gap-4'>
      <h2 className='font-bold text-5 text-primary-darkPurple md:text-7'>
        <Trans>Membership registered</Trans> 🥳
      </h2>

      <WheelChangeImageCard
        cardText={t`The benefits have already been linked to your account, and discounts will be automatically applied to your shopping cart`}
      />
    </div>
  );
};
