import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';

export const useMembershipPrograms = () => {
  const { currentUserGroupId } = useAuthContext();

  return noddiAsync.useGet({
    type: URLKeys.getMembershipPrograms,
    input: { userGroupId: currentUserGroupId as number },
    queryConfig: {
      staleTime: Infinity,
      enabled: !!currentUserGroupId
    }
  });
};
