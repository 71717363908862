import { ServiceCategoryFromAddress } from 'noddi-async/src/types';
import { IconName, NoddiIcon, colors } from 'noddi-ui';
import { formatCurrencyAmount } from 'noddi-util';

import { getServiceCategoryTranslations } from '../../utils/translation';

const getServiceCategoryIcon = ({ serviceCategoryName }: { serviceCategoryName: string }): IconName => {
  if (serviceCategoryName === 'Wheel change') {
    return 'WheelChange';
  } else if (serviceCategoryName === 'Car wash') {
    return 'Drop';
  } else if (serviceCategoryName === 'Wheel storage') {
    return 'Home';
  } else {
    return 'Wheel';
  }
};

type Props = {
  serviceCategory: ServiceCategoryFromAddress;
  navigateToNextStepWhenAvailableService?: () => void;
  translations: { from: string };
};

export const AvailableServiceCategory = ({
  translations,
  serviceCategory,
  navigateToNextStepWhenAvailableService
}: Props) => {
  const data = getServiceCategoryTranslations({ serviceCategoryName: serviceCategory.name });
  const iconName = getServiceCategoryIcon({ serviceCategoryName: serviceCategory.name });

  if (!data) {
    return null;
  }

  return (
    <div className='flex w-fit gap-2' onClick={navigateToNextStepWhenAvailableService}>
      <NoddiIcon className='mt-[2px]' name={iconName} color={colors.primary.black} size='large' />

      <div className='flex flex-col'>
        <span className='text-5 sm:text-6'>{data.name}</span>
        <div className='flex gap-1'>
          <span className='text-3 sm:text-4'>{translations.from}</span>
          <span className='text-3 font-semibold sm:text-4'>
            {formatCurrencyAmount(serviceCategory.price, 0, serviceCategory.currency)}
          </span>
        </div>
      </div>
    </div>
  );
};
