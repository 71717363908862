import { Form, Formik } from 'formik';
import { URLKeys, noddiAsync } from 'noddi-async';
import { LanguageCodeType } from 'noddi-provider';
import { trimAllWhiteCharacters } from 'noddi-util';
import { KeyboardEvent, useEffect } from 'react';
import 'react-international-phone/style.css';

import { ApiErrorMessage, ErrorCodes, NoddiButton } from '../../../';
import { NoddiPhoneInput } from '../../Elements/Form/NoddiFormPhoneInput';
import { isPhoneNumberValid } from './isPhoneNumberValid';

type PhoneNumberFormProps = {
  mobileNumber: string;
  setMobileNumber: (mobileNumber: string) => void;
  setCodeSentSuccessfully: (isSuccessful: true) => void;
  hideText?: boolean;
  translations: {
    enterNumberToReceiveVerificationCode: string;
    resend: string;
    sendCode: string;
    didntReceiveSms: string;
  };
  language?: LanguageCodeType;
  errorCodes: ErrorCodes;
};

const PhoneNumberForm = ({
  mobileNumber,
  setMobileNumber,
  setCodeSentSuccessfully,
  hideText,
  translations,
  language,
  errorCodes
}: PhoneNumberFormProps) => {
  const phoneNumberDto = ({ urlEncode = false }: { urlEncode?: boolean }) => {
    const serializedMobileNumber = trimAllWhiteCharacters(mobileNumber);

    return urlEncode ? encodeURIComponent(serializedMobileNumber) : serializedMobileNumber;
  };

  const { isSuccess, isLoading, isRefetching, refetch, error } = noddiAsync.useGet({
    type: URLKeys.getPhoneNumberVerification,
    input: {
      phoneNumber: phoneNumberDto({ urlEncode: true })
    },
    queryConfig: {
      enabled: false
    }
  });

  useEffect(() => {
    if (isSuccess) {
      setCodeSentSuccessfully(true);
    }
  }, [isSuccess]);

  const sendPhoneNumberVerification = () => {
    refetch();
  };

  const phoneNumberIsValid = () => isPhoneNumberValid(mobileNumber);

  const isFetching = isLoading || isRefetching;

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (phoneNumberIsValid() && event.key === 'Enter') {
      sendPhoneNumberVerification();
    }
  };

  return (
    <Formik
      initialValues={{
        mobileNumber
      }}
      onSubmit={sendPhoneNumberVerification}
    >
      {() => (
        <Form>
          <div className='flex flex-col gap-3'>
            {!hideText && <p>{translations.enterNumberToReceiveVerificationCode}</p>}
            <NoddiPhoneInput
              language={language}
              onChange={setMobileNumber}
              value={mobileNumber}
              onKeyDown={onKeyDown}
              FormHelperTextProps={{
                sx: { margin: 0 }
              }}
              helperText={error && <ApiErrorMessage errorCodes={errorCodes} error={error} isSmallVersion />}
            />
            {isSuccess ? (
              <div className='flex items-center justify-between'>
                <p>{translations.didntReceiveSms}</p>
                <div>
                  <NoddiButton
                    type='submit'
                    disabled={!phoneNumberIsValid() || isFetching}
                    loading={isLoading || isFetching}
                    variant='link'
                    size='small'
                  >
                    {translations.resend}
                  </NoddiButton>
                </div>
              </div>
            ) : (
              <div className='flex items-center justify-between gap-4'>
                <NoddiButton type='submit' disabled={!phoneNumberIsValid()} loading={isLoading} className='ml-auto'>
                  {translations.sendCode}
                </NoddiButton>
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export { PhoneNumberForm };
