import { useAuthContext } from 'noddi-provider';
import { NoddiDropdown } from 'noddi-ui';

interface UserGroupDropdownProps {
  handleClearDataWhenImpersonating?: () => void;
}
const UserGroupDropdown = ({ handleClearDataWhenImpersonating }: UserGroupDropdownProps) => {
  const { userData, updateUserData, updateImpersonatedData, impersonatedUser } = useAuthContext();

  const hasMultipleUserGroups = !!(userData && userData.user.userGroups.length > 1);

  if (!userData) {
    return null;
  }
  if (!hasMultipleUserGroups) {
    return null;
  }

  const selectedUserGroup = userData.user.userGroups.find((userGroup) => userGroup.isSelected);
  const selectedUserGroupName = selectedUserGroup?.name ?? '...';

  const userGroupOptions = userData.user.userGroups.map((userGroup) => ({
    label: userGroup.name,
    value: userGroup.id
  }));

  return (
    <NoddiDropdown
      startIcon='UserCircle'
      options={userGroupOptions}
      placeholder={selectedUserGroupName}
      selectedOption={userGroupOptions.find((ug) => ug.value === selectedUserGroup?.id)}
      onSelect={(option) => {
        const newUserData = userData.user;
        newUserData.userGroups = userData.user.userGroups.map((ug) => {
          ug.isSelected = ug.id === option.value;
          return ug;
        });

        if (impersonatedUser) {
          updateImpersonatedData({ ...userData, user: newUserData });
        } else {
          updateUserData({ ...userData, user: newUserData });
        }
        handleClearDataWhenImpersonating?.();
      }}
    />
  );
};

export default UserGroupDropdown;
