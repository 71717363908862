import { Trans } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { LoadingScreen, NoddiButton } from 'noddi-ui';
import { useState } from 'react';

import { ErrorPageWithTranslations } from '../../components/ErrorPageWithTrans';
import CarItemCard from '../../components/Home/CarItemCard';

const DEFAULT_NUMBER_OF_CARS_TO_DISPLAY = 3;

type CarsProps = {
  userGroupId: number;
};

const Cars = ({ userGroupId }: CarsProps) => {
  const [showAllCars, setShowAllCars] = useState(false);

  const {
    isPending: isCarsPending,
    data: paginatedCars,
    error: carsError
  } = noddiAsync.useGet({
    type: URLKeys.getPaginatedCarsForUserGroups,
    input: { userGroupId, pageSize: showAllCars ? 1000 : DEFAULT_NUMBER_OF_CARS_TO_DISPLAY, page: 1 }
  });

  if (carsError) {
    return <ErrorPageWithTranslations apiError={carsError} />;
  }

  if (isCarsPending) {
    return <LoadingScreen />;
  }
  const cars = paginatedCars?.results;
  const totalCars = paginatedCars?.count;
  const isAreMoreCars = totalCars > DEFAULT_NUMBER_OF_CARS_TO_DISPLAY;

  if (!cars.length) {
    return null;
  }

  return (
    <div className='flex flex-col gap-2 md:gap-4'>
      <h2 className='font-bold text-5 md:text-7'>
        <Trans>Cars</Trans>
      </h2>
      <div className='flex flex-col gap-3'>
        {cars.map((car) => (
          <CarItemCard
            key={car.licensePlateNumber}
            carName={`${car.make} ${car.model}`}
            licensePlate={car.licensePlateNumber}
            carId={car.id}
          />
        ))}
      </div>
      {isAreMoreCars && (
        <div className='flex justify-between px-5'>
          {!showAllCars && (
            <p className='font-bold text-5'>{`+ ${totalCars - DEFAULT_NUMBER_OF_CARS_TO_DISPLAY} more`}</p>
          )}
          <NoddiButton
            onClick={() => setShowAllCars((prev) => !prev)}
            variant='secondary'
            size='small'
            className='ml-auto'
          >
            {showAllCars ? <Trans>Show less</Trans> : <Trans>Show all</Trans>}
          </NoddiButton>
        </div>
      )}
    </div>
  );
};

export default Cars;
