import { Trans, t } from '@lingui/macro';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { NoddiAsyncError } from 'noddi-async/src/types';
import { NoddiButton, NoddiDialog } from 'noddi-ui';

interface CancelDialogProps {
  open: boolean;
  onClose: () => void;
  bookingIdNumber: number;
  cancelBooking: UseMutateAsyncFunction<
    undefined,
    NoddiAsyncError,
    {
      bookingId: number;
    },
    unknown
  >;
}

export const CancelDialog = ({ open, onClose, cancelBooking, bookingIdNumber }: CancelDialogProps) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <NoddiDialog title={t`Cancel booking?`} onClose={handleClose} open={open}>
      <div className='flex flex-col gap-2'>
        <p className='mb-5'>
          <Trans>
            Canceling the booking is completely free of charge. Cancelation is available up to 24 hours before the start
            time.
          </Trans>
        </p>

        <div className='flex justify-end gap-2'>
          <NoddiButton
            variant='destructive'
            onClick={() => {
              cancelBooking({
                bookingId: bookingIdNumber
              });
              handleClose();
            }}
          >
            <Trans>Cancel</Trans>
          </NoddiButton>
        </div>
      </div>
    </NoddiDialog>
  );
};
