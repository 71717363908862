import { t } from '@lingui/macro';
import { useNavigate } from 'react-router-dom';

import BookingScreen from '../../BookingScreen';
import { servicesStepPath } from '../../bookingFlowPaths';
import { useStepCompletionValidation } from '../../hooks/useStepCompletionValidation';
import CarSelector from './CarSelector';

const Car = () => {
  const navigate = useNavigate();
  const { isCarStepValid } = useStepCompletionValidation();

  return (
    <BookingScreen
      onNextClicked={() => navigate(servicesStepPath)}
      disableNextButton={!isCarStepValid}
      title={t`Add one or more cars`}
      hideShoppingCart
    >
      <CarSelector />
    </BookingScreen>
  );
};

export default Car;
