import { Trans } from '@lingui/macro';
import { AddressProps, ServiceAreasDataFromCoordinate } from 'noddi-async/src/types';
import { getLongAddressName, hasStreetNumber, useIsMobile, useScrollIntoView } from 'noddi-ui';

import NoServiceOffered from './NoServiceOffered';
import { ServiceAvailabilityInfo } from './ServiceAvailabilityInfo';

type SelectedAddressDetailsProps = {
  isServiceAreaError: boolean;
  serviceAreas: ServiceAreasDataFromCoordinate | undefined;
  hasServiceCategories: boolean;
  address: AddressProps | null;
  hideServiceInfoBox?: boolean;
  navigateToNextStepWhenAvailableService?: () => void;
  translations: {
    noStreetNumberNotice: string;
    noServiceOfferedNotice: string;
    from: string;
    hooray: string;
    youCanBookTheseServicesToYourAdress: string;
    weDoNotOfferTheseServicesAtThisAddress: string;
  };
};

const SelectedAddressDetails = ({
  translations,
  isServiceAreaError,
  serviceAreas,
  hasServiceCategories,
  address,
  hideServiceInfoBox,
  navigateToNextStepWhenAvailableService
}: SelectedAddressDetailsProps) => {
  const commonStyles = 'p-4 mt-4 rounded-md text-secondary-black';
  const isMobile = useIsMobile();

  const containerRef = useScrollIntoView(!!address && isMobile); // Use the hook with a boolean flag

  if (!address) {
    return null;
  }

  if (isServiceAreaError) {
    return (
      <p ref={containerRef} className={`bg-signal-danger/40 ${commonStyles} `}>
        <Trans>
          Obs, something seems to have crashed. If you are connected to wifi at work, it might be a firewall issue. Try
          a different network and please don't hesitate to contact us if the problem does not disappear
        </Trans>
      </p>
    );
  }

  if (!hasStreetNumber(address)) {
    return (
      <p ref={containerRef} className={`bg-signal-warning50 ${commonStyles}`}>
        {translations.noStreetNumberNotice}
      </p>
    );
  }

  if (hideServiceInfoBox && !hasServiceCategories) {
    return <NoServiceOffered translations={translations} address={address} />;
  }

  return hasServiceCategories ? (
    <div style={{ marginTop: 24 }} ref={containerRef}>
      <ServiceAvailabilityInfo
        translations={{
          from: translations.from,
          hooray: translations.hooray,
          youCanBookTheseServicesToYourAdress: translations.youCanBookTheseServicesToYourAdress,
          weDoNotOfferTheseServicesAtThisAddress: translations.weDoNotOfferTheseServicesAtThisAddress
        }}
        address={address ? getLongAddressName(address) : undefined}
        serviceCategories={serviceAreas?.serviceCategories ?? []}
        unavailableServiceCategories={serviceAreas?.unavailableServiceCategories ?? []}
        navigateToNextStepWhenAvailableService={navigateToNextStepWhenAvailableService}
      />
    </div>
  ) : (
    <NoServiceOffered translations={translations} address={address} />
  );
};

export { SelectedAddressDetails };
