import { Trans } from '@lingui/macro';
import { MembershipDiscountProps } from 'noddi-async/src/types';
import { formatCurrencyAmount } from 'noddi-util';

import { ActiveCoupon } from '../../../../stores/CouponStore';
import { getNewPriceForSalesItem } from './utils';

interface DiscountItemProps {
  name: string;
  price: number;
  discount?: MembershipDiscountProps;
  coupon?: ActiveCoupon;
}

const DiscountItem = ({ name, price, discount, coupon }: DiscountItemProps) => {
  const discountedPrice = price - getNewPriceForSalesItem({ price, discount, coupon });
  return (
    <div className='flex flex-row justify-between gap-4'>
      <p>{name}</p>
      <p className='text-signal-success'>-{formatCurrencyAmount(discountedPrice, 0)}</p>
    </div>
  );
};
interface DiscountSectionProps {
  activeDiscountsForSalesItems: (MembershipDiscountProps & { price: number })[];
  activeCouponsForSalesItems: (ActiveCoupon & { price: number })[];
}

const DiscountSection = ({ activeDiscountsForSalesItems, activeCouponsForSalesItems }: DiscountSectionProps) => {
  return (
    <div className='flex flex-col gap-1'>
      <p className='text-5'>
        <Trans>Discounts</Trans>
      </p>
      <div className='flex flex-col gap-1'>
        {activeDiscountsForSalesItems.map((discount, idx) => (
          <DiscountItem
            key={`discount-${discount.id}-${idx}`}
            name={discount.name}
            price={discount.price}
            discount={discount}
          />
        ))}
        {activeCouponsForSalesItems.map((coupon) => (
          <DiscountItem key={`coupon-${coupon.id}`} name={coupon.namePublic} price={coupon.price} coupon={coupon} />
        ))}
      </div>
    </div>
  );
};

export default DiscountSection;
