export const errorCodes = {
  invalid_phone_number: 'Phone number is not valid',
  invalid_verification_code: 'The verification code is not valid',
  invalid_code: 'Invalid code',
  user_not_naf_member:
    'We could not find you in the NAF registers. You have to be a member to activate the benefits. If this is wrong, please contact hei@noddi.no',
  new_user_not_eligible_for_campaign:
    'This campaign is intended for new members only. Therefore, we will not activate the coupons for you',
  booking_time_window_not_bookable:
    'The chosen time window has unfortunately been taken. Please choose another time window in the previous step.',
  user_phone_or_email_already_exists:
    'It looks like there already exists a user with the given email. Contact hei@noddi.no if you are unable to log into your account',
  capacity_contribution_already_exists:
    'You have already added a availability for one of these dates for this license area',
  car_not_found: 'Could not find the vehicle that was searched for',
  credentials_invalid: 'The credentials you provided are incorrect',
  phone_number_verification_error: 'The phone number you provided is not valid',
  pin_code_verification_error: 'The pin code you provided is not valid',
  password_verification_failed: 'The passwords do not match',
  genericErrorMessage: 'Obs.. Something seems to have have crashed.'
} as const;
