import { useAuthContext } from 'noddi-provider';

import HomeContent from './HomeContent';

const Home = () => {
  const { currentUserGroupId: userGroupId, userData, hasTireHotel } = useAuthContext();

  if (!userGroupId || !userData) {
    return null;
  }

  return <HomeContent userGroupId={userGroupId} userData={userData} hasTireHotel={!!hasTireHotel} />;
};

export default Home;
