import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { LoadingScreen } from 'noddi-ui';

import { Trans } from '@lingui/macro';
import { ErrorPageWithTranslations } from '../../components/ErrorPageWithTrans';
import ContractList from './ContractList';

interface TireHotelProps {
  showHeading?: boolean;
}
const TireHotel = ({ showHeading }: TireHotelProps) => {
  const { currentUserGroupId } = useAuthContext();

  const {
    data: tireHotelContracts,
    isPending,
    error
  } = noddiAsync.useGet({
    type: URLKeys.getTireHotelContracts,
    input: { userGroupId: currentUserGroupId as number },
    queryConfig: {
      enabled: !!currentUserGroupId
    }
  });

  const vinNumbers =
    tireHotelContracts?.map((contract) => contract.car.vinNumber).filter((x): x is string => Boolean(x)) ?? [];

  const {
    data: tireHotelOptions,
    isLoading: optionsIsLoading,
    error: optionsError
  } = noddiAsync.useGet({
    type: URLKeys.getTireHotelOptions,
    input: { vinNumbers, userGroupId: currentUserGroupId },
    queryConfig: {
      enabled: vinNumbers.length > 0
    }
  });

  if (isPending || optionsIsLoading) {
    return <LoadingScreen />;
  }

  if (!currentUserGroupId || error || optionsError) {
    return <ErrorPageWithTranslations apiError={[error, optionsError]} />;
  }

  if (tireHotelContracts?.length === 0) {
    return null;
  }

  return (
    <div className='flex flex-col gap-2 md:gap-4'>
      {showHeading && (
        <h2 className='font-bold text-5 md:text-7'>
          <Trans>Tire hotel</Trans>
        </h2>
      )}
      <ContractList tireHotelContracts={tireHotelContracts} tireHotelOptions={tireHotelOptions ?? []} />
    </div>
  );
};

export default TireHotel;
