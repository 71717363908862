import { t } from '@lingui/macro';
import { dateOfBirthRegex } from 'noddi-util';
import * as yup from 'yup';

const validateMembershipNumber = (value: string | undefined) => typeof value === 'string' && value.length >= 4;

const checkLeapYear = (value: string | undefined) => {
  const parts = value?.split(/[-/.]/);
  if (parts && parts.length === 3) {
    const day = parseInt(parts[0] ?? '', 10);
    const month = parseInt(parts[1] ?? '', 10);
    const year = parseInt(parts[2] ?? '', 10);

    if (month === 2) {
      if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
        return day <= 29;
      } else {
        return day <= 28;
      }
    }
    return true;
  }
  return false;
};

const membershipNumberSchema = () =>
  yup
    .string()
    .required(t`Membership number is required`)
    .test('is-valid-membership-number', t`Must consist of at least 4 numbers`, validateMembershipNumber);

const dateOfBirthSchema = () =>
  yup
    .string()
    .required(t`Date of birth is required`)
    .matches(dateOfBirthRegex, t`Date of birth must be in the format DD.MM.YYYY, DD-MM-YYYY, DD/MM/YYYY or DDMMYYYY`)
    .test('is-leap-year', t`Date of birth is not valid`, checkLeapYear);

const membershipDetailSchema = (membershipLogin: boolean) =>
  membershipLogin
    ? yup.object().shape({
        membershipNumber: membershipNumberSchema()
      })
    : yup.object().shape({
        dateOfBirth: dateOfBirthSchema()
      });

export { membershipDetailSchema };
