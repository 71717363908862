import { Trans, t } from '@lingui/macro';
import { UserDataProps } from 'noddi-async/src/types';
import UserGroupDropdown from '../../components/Layouts/UserGroupDropdown';
import NewBookingButton from '../../components/NewBookingButton/NewBookingButton';

type HomePageHeaderProps = {
  userData: UserDataProps;
  isFirstTimeCustomer: boolean;
  isMobile: boolean;
};

const HomePageHeader = ({ userData, isFirstTimeCustomer, isMobile }: HomePageHeaderProps) => {
  const selectedGroup = userData?.user.userGroups.find((ug) => ug.isSelected);

  const title = selectedGroup?.isOrganization ? selectedGroup?.name : `${t`Hi`} ${userData.user.firstName}!`;

  const hasMultipleUserGroups = !!(userData.user.userGroups && userData.user.userGroups.length > 1);

  return (
    <div className='flex flex-col gap-3'>
      <div className='flex items-center justify-between gap-6'>
        <div className='flex flex-col gap-2 '>
          <h1 className='font-bold text-9 md:text-12'>{title}</h1>
          <div className=' w-fit  min-w-50 max-w-75 '>
            <UserGroupDropdown />
          </div>
        </div>
        {!isFirstTimeCustomer && !isMobile && <NewBookingButton defaultSize />}
      </div>
      <p className='font-bold text-5 text-primary-purple md:text-6'>
        {!isFirstTimeCustomer && !hasMultipleUserGroups && <Trans>Welcome back!</Trans>}
      </p>
      {!isFirstTimeCustomer && isMobile && <NewBookingButton />}
    </div>
  );
};

export default HomePageHeader;
