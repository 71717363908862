import { MembershipDiscountProps } from 'noddi-async/src/types';
import { NoddiBasicCard } from 'noddi-ui';

const MembershipDiscount = (props: { discount: MembershipDiscountProps }) => {
  const { discount } = props;

  return (
    <NoddiBasicCard className='flex flex-col gap-3'>
      <h6 className='text-5'>{discount.name}</h6>
      <p className='text-secondary-black'>{discount.description}</p>
    </NoddiBasicCard>
  );
};

export default MembershipDiscount;
