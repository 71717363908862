import { t } from '@lingui/macro';
import { NoddiButton } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';

import routes from '../../appRoutes';
import useCleanupBooking from '../../hooks/useCleanupBooking';

function useCleanupBookingAndGoToNewOne() {
  const { cleanupBookingData } = useCleanupBooking();
  const navigate = useNavigate();

  function navigateToNewBooking() {
    cleanupBookingData();
    navigate(routes.newBookingAddressStep.getPath());
  }

  return { navigateToNewBooking };
}

type NewBookingButtonProps = {
  fullWidth?: boolean;
  defaultSize?: boolean;
};
const NewBookingButton = ({ fullWidth, defaultSize }: NewBookingButtonProps) => {
  const { navigateToNewBooking } = useCleanupBookingAndGoToNewOne();
  return (
    <NoddiButton
      fullWidth={fullWidth}
      size={defaultSize ? 'default' : 'small'}
      endIcon='ArrowRight'
      onClick={navigateToNewBooking}
    >{t`New booking`}</NoddiButton>
  );
};

export default NewBookingButton;
