import { Trans, t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import {
  LoadingScreen,
  NoddiButton,
  NoddiFeedbackBox,
  OrderSummary,
  getLongAddressName,
  useNoddiToast
} from 'noddi-ui';
import { DateFormats, format } from 'noddi-util';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import routes from '../../../appRoutes';
import getCommonTranslations from '../../../commonTranslations';
import { ApiErrorMessageWithTrans } from '../../../components/ApiErrorMessageWithTrans';
import ContentWrapper from '../../../components/Layouts/ContentWrapper';
import { CancelDialog } from './CancelDialog';
import { MetaSection } from './MetaSection';
import NoBookingDataFound from './NoBookingDataFound';

const BookingDetails = () => {
  const { currentUserGroupId: userGroupId } = useAuthContext();
  const navigate = useNavigate();
  const { id: bookingId } = useParams();
  const [cancelDialogIsOpen, setCancelDialogIsOpen] = useState(false);
  const { noddiToast } = useNoddiToast();

  const bookingIdAsString = bookingId as string;
  const {
    isPending: isBookingPending,
    data: bookingData,
    isError,
    refetch: refetchBookingData
  } = noddiAsync.useGet({
    type: URLKeys.getUserBooking,
    input: { userGroupId: userGroupId as number, bookingId: bookingIdAsString },
    queryConfig: {
      enabled: !!userGroupId && !!bookingId
    }
  });

  const { mutateAsync: cancelBooking, isPending: isCancelling } = noddiAsync.usePost({
    type: URLKeys.getCancelBooking,
    queryConfig: {
      onSuccess: () => {
        refetchBookingData();
      }
    }
  });

  const bookingIdNumber = Number(bookingId);

  const {
    isRefetching: isDownloadingReceiptRefetching,
    refetch: downloadReceipt,
    error: downloadReceiptError
  } = noddiAsync.useGet({
    type: URLKeys.downloadBookingReceipt,
    input: {
      bookingId: bookingIdNumber
    },
    queryConfig: {
      enabled: false
    }
  });

  const {
    isPending: isBookingPermissionsPending,
    data: bookingPermissions,
    isError: isBookingPermissionsError
  } = noddiAsync.useGet({
    type: URLKeys.getEditBookingPermissions,
    input: { id: bookingIdAsString },
    queryConfig: {
      enabled: !!bookingId
    }
  });

  const getDateText = () => {
    if (bookingData?.completedAt) {
      return `${t`Delivered :`} ${format(bookingData?.completedAt, DateFormats.FULL_MONTH_YEAR_DATE_TIME)}`;
    } else if (bookingData?.startedAt) {
      return `${t`Started :`} ${format(bookingData?.startedAt, DateFormats.FULL_MONTH_YEAR_DATE_TIME)}`;
    } else if (bookingData?.bookingTimeWindow) {
      return `${t`Scheduled :`} ${format(
        bookingData?.bookingTimeWindow.startPublic,
        DateFormats.FULL_MONTH_YEAR_DATE_TIME
      )} - ${format(bookingData?.bookingTimeWindow.endPublic, DateFormats.TIME)}`;
    } else {
      return '?';
    }
  };

  if (isBookingPending || isCancelling || isBookingPermissionsPending) {
    return <LoadingScreen />;
  }
  if (isError || !bookingData || isBookingPermissionsError) {
    return <NoBookingDataFound />;
  }

  function navigateToEditBookingTimeWindow(id: number) {
    navigate(routes.editMyBookingTimeWindow.getPath({ id }));
  }

  const showActionButtons = !bookingData?.isCancelled && !bookingData?.isCancelClosed;
  const showPaymentButton = bookingData?.completedAt && bookingData.amountDue && bookingData.amountDue > 0;

  return (
    <ContentWrapper backButtonPath='/' title={t`Your booking`}>
      <div className='flex flex-col gap-4'>
        <div className='flex justify-end'>
          {showActionButtons ? (
            <div className='flex gap-2'>
              {bookingPermissions.timeWindows.canEdit && (
                <NoddiButton
                  variant='secondary'
                  onClick={() => navigateToEditBookingTimeWindow(bookingData.id)}
                  startIcon='Edit'
                >
                  <Trans>Change time</Trans>
                </NoddiButton>
              )}
              {/* Hidden untill API for getting correct pricing is added, also missing getting servicearea and categori from bookingid <ExpandBookingEntry bookingData={bookingData} /> */}
            </div>
          ) : (
            !bookingData?.isCancelled &&
            !bookingData?.completedAt && (
              <NoddiFeedbackBox
                size='small'
                heading={t`This booking cannot be changed or canceled due to the proximity to the start time. For questions, contact us at hei@noddi.no`}
                variant='info'
              />
            )
          )}
          {showPaymentButton ? (
            <NoddiButton onClick={() => navigate(routes.bookingInfo.getPath({ slug: bookingData?.slug }))}>
              <Trans>Pay</Trans>
            </NoddiButton>
          ) : null}
          {bookingData?.hasReceipt && (
            <div className='flex flex-col items-end'>
              {downloadReceiptError && (
                <div className='mb-3'>
                  <ApiErrorMessageWithTrans error={downloadReceiptError} isSmallVersion />
                </div>
              )}

              <NoddiButton
                loading={isDownloadingReceiptRefetching}
                startIcon='Download'
                variant='secondary'
                onClick={async () => {
                  const { error } = await downloadReceipt();

                  if (error) {
                    return noddiToast.error(t`Could not download receipt`);
                  }

                  return refetchBookingData();
                }}
              >
                <Trans>Receipt</Trans>
              </NoddiButton>
            </div>
          )}
        </div>
        <div>
          {bookingData && (
            <OrderSummary
              translations={getCommonTranslations().orderSummary}
              MetaSection={
                <MetaSection
                  isPaid={bookingData?.hasReceipt}
                  isCancelled={bookingData?.isCancelled}
                  slug={bookingData?.slug}
                  address={bookingData?.address ? getLongAddressName(bookingData.address) : undefined}
                  dataText={getDateText()}
                  canEditComment={bookingPermissions.canEditComments}
                  commentsUser={bookingData.commentsUser ?? ''}
                  bookingId={bookingIdAsString}
                />
              }
              showHeader={false}
              carItemLines={bookingData.cars}
              otherOrderLines={bookingData.otherOrderLines}
              discountLines={bookingData.discountOrderLines}
            />
          )}
        </div>
        {showActionButtons && (
          <NoddiButton
            variant='destructive'
            className='self-end'
            onClick={() => setCancelDialogIsOpen(true)}
            disabled={isCancelling}
          >
            <Trans>Cancel booking</Trans>
          </NoddiButton>
        )}
      </div>
      <CancelDialog
        open={cancelDialogIsOpen}
        onClose={() => setCancelDialogIsOpen(false)}
        bookingIdNumber={bookingIdNumber}
        cancelBooking={cancelBooking}
      />
    </ContentWrapper>
  );
};

export default BookingDetails;
