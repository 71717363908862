import { t } from '@lingui/macro';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useFetchAddons from '../../../../../../hooks/ApiHooks/useFetchAddons';
import { SalesItemsSelector } from '../../../../../BookingFlow/Steps/SalesItems';
import useHasSelectedWheelStorage from '../../../../../BookingFlow/hooks/useHasSelectedWheelStorage';
import { useStepCompletionValidation } from '../../../../../BookingFlow/hooks/useStepCompletionValidation';
import ExpandBookingScreen from '../../ExpandBookingScreen';
import useExpandBookingPaths from '../../hooks/useExpandBookingPaths';

export const SalesItems = () => {
  const navigate = useNavigate();
  const { tireHotelPath, summaryPath, addonsPath } = useExpandBookingPaths();
  const { isSalesItemStepValid } = useStepCompletionValidation();
  const hasSelectedWheelStorage = useHasSelectedWheelStorage();

  const { refetch, isLoading: fetchingAddons } = useFetchAddons();
  const [isLoading, setIsLoading] = useState(false);

  const navigateToNextStep = async () => {
    const { data: addons } = await refetch();

    if (hasSelectedWheelStorage) {
      return navigate(tireHotelPath);
    }

    if (addons?.length) {
      return navigate(addonsPath);
    }

    return navigate(summaryPath);
  };

  async function onNextButtonClicked() {
    setIsLoading(true);
    await navigateToNextStep();
    setIsLoading(false);
  }

  return (
    <ExpandBookingScreen
      onNextClicked={onNextButtonClicked}
      disableNextButton={!isSalesItemStepValid}
      title={t`What can we help you with?`}
    >
      <SalesItemsSelector isLoading={fetchingAddons || isLoading} />
    </ExpandBookingScreen>
  );
};
