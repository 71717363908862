import { Divider, SxProps } from '@mui/material';
import Stack from '@mui/material/Stack';
import { ReactNode, Ref, useRef } from 'react';

import { ExpandingArrow } from '../Icons';
import { StyledLinkRow } from './StyledLinkRow';
import { StyledRow } from './StyledRow';
import { TypographyOrCustom } from './TypographyOrCustom';

type KeyValueRowProps = {
  header: ReactNode;
  value?: ReactNode | undefined | null;
  onClick?: () => void;
  showDivider?: boolean;
  openInNewTab?: boolean;
  to?: string;
  direction?: 'row' | 'column';
  sx?: SxProps;
};

export const KeyValueRow = ({
  header,
  value,
  onClick,
  to,
  openInNewTab,
  showDivider = true,
  direction = 'row',
  sx = {}
}: KeyValueRowProps) => {
  const clickable = !!onClick || !!to;

  const arrowRef = useRef<HTMLDivElement | HTMLAnchorElement>(null);

  const RowContent = () => (
    <>
      <Stack direction={direction} justifyContent='space-between' sx={{ backgroundColor: 'inherit', ...sx }}>
        <TypographyOrCustom value={header} />
        <Stack direction='row' alignItems='center'>
          {value && <TypographyOrCustom value={value} />}
          {clickable && <ExpandingArrow parentRef={arrowRef} />}
        </Stack>
      </Stack>
      {showDivider && <Divider sx={{ opacity: '1' }} />}
    </>
  );

  return to ? (
    <StyledLinkRow to={to} target={openInNewTab ? '_blank' : '_self'} ref={arrowRef as Ref<HTMLAnchorElement>}>
      <RowContent />
    </StyledLinkRow>
  ) : (
    <StyledRow onClick={onClick} clickable={clickable} ref={arrowRef as Ref<HTMLDivElement>}>
      <RowContent />
    </StyledRow>
  );
};
