import { cva, type VariantProps } from 'class-variance-authority';

import { colors } from '../../tailwind-design-preset';
import { NoddiIcon } from '../atoms/NoddiIcon';
import { IconName, IconSize } from '../atoms/types/icon';
import { cn } from '../helpers/utilts';

// Don't add hover as effect persists on mobile after pressed, which makes the button look disabled.
const iconButtonVariants = cva('relative w-fit cursor-pointer rounded-full p-2 transition-all', {
  variants: {
    variant: {
      primary: 'bg-primary-purple active:opacity-80',
      secondary: 'border border-primary-darkPurple bg-primary-white active:bg-systemColors-snow',
      destructive: 'bg-signal-danger50 active:opacity-80',
      ghost: 'active:bg-secondary-white active:opacity-30'
    },
    disabled: {
      true: 'pointer-events-none cursor-not-allowed',
      false: 'cursor-pointer'
    }
  },
  defaultVariants: {
    variant: 'primary',
    disabled: false
  }
});

const iconColorVariant = cva('', {
  variants: {
    variant: {
      primary: colors.primary.white,
      secondary: colors.primary.darkPurple,
      destructive: colors.primary.orange,
      ghost: colors.primary.black
    }
  },
  defaultVariants: {
    variant: 'primary'
  }
});

const loadingSpinnerVariants = cva(
  'absolute inset-0 box-border size-full animate-spin-slow rounded-full border-2 border-systemColors-transparent',
  {
    variants: {
      variant: {
        primary: 'border-t-primary-white',
        secondary: 'border-t-primary-darkPurple',
        destructive: 'border-t-primary-orange',
        ghost: 'border-t-primary-black'
      }
    },
    defaultVariants: {
      variant: 'primary'
    }
  }
);

interface NoddiIconButtonProps extends VariantProps<typeof iconButtonVariants> {
  iconName: IconName;
  iconSize?: IconSize;
  onClick?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  className?: string;
}

export const NoddiIconButton = ({
  iconName,
  iconSize = 'large',
  variant = 'primary',
  onClick,
  isLoading,
  isDisabled,
  className
}: NoddiIconButtonProps) => {
  return (
    <button className={cn(iconButtonVariants({ variant, disabled: isDisabled }), className)} onClick={onClick}>
      <NoddiIcon name={iconName} size={iconSize} color={iconColorVariant({ variant })} />
      {isLoading && <div className={loadingSpinnerVariants({ variant })} />}
    </button>
  );
};
