import { URLKeys, noddiAsync } from 'noddi-async';

import { useBookingContext } from '../../contexts/BookingContext';

const useFetchAddons = () => {
  const {
    salesItems,
    bookingInputData: { selectedCars }
  } = useBookingContext();

  return noddiAsync.useGet({
    type: URLKeys.getAddonsForBooking,
    input: {
      salesItemIds: salesItems.filter((item) => !item.isAddon).map((item) => item.id),
      carSizeIds: selectedCars.map((car) => car.carSizeId)
    },
    queryConfig: {
      enabled: false
    }
  });
};

export default useFetchAddons;
