import { t } from '@lingui/macro';
import { useNavigate } from 'react-router-dom';
import { AddonsSelector } from '../../../../../BookingFlow/Steps/Addons';
import ExpandBookingScreen from '../../ExpandBookingScreen';
import useExpandBookingPaths from '../../hooks/useExpandBookingPaths';

const Addons = () => {
  const navigate = useNavigate();
  const { summaryPath } = useExpandBookingPaths();

  return (
    <ExpandBookingScreen
      onNextClicked={() => {
        navigate(summaryPath);
      }}
      title={t`Do you need help with anything else?`}
    >
      <AddonsSelector />
    </ExpandBookingScreen>
  );
};

export default Addons;
