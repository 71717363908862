import { Typography } from '@mui/material';
import { UserGroupAddress } from 'noddi-async/src/types';
import { NoddiBasicCard, NoddiIcon, colors, getLongAddressName } from 'noddi-ui';

type Props = {
  userGroupAddress: UserGroupAddress;
};

const Address = ({ userGroupAddress }: Props) => {
  const { address } = userGroupAddress;
  return (
    <NoddiBasicCard className='flex flex-col gap-3'>
      <div className='flex gap-2'>
        <NoddiIcon name='LocationPin' color={colors.primary.darkPurple} size='large' />
        <div>
          {userGroupAddress.name && <Typography variant='h6'>{userGroupAddress.name}</Typography>}
          <Typography variant='body2'>{getLongAddressName(address)}</Typography>
        </div>
      </div>
    </NoddiBasicCard>
  );
};

export default Address;
