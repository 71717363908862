import { URLKeys, noddiAsync } from 'noddi-async';
import { NoddiCircularLoader } from 'noddi-ui';
import { useState } from 'react';

import { ErrorPageWithTranslations } from '../../components/ErrorPageWithTrans';
import BookingsSection from './BookingSection';
import { DEFAULT_NUMBER_OF_BOOKINGS_SHOWN } from './HomeContent';

const UpcomingBookings = ({ userGroupId }: { userGroupId: number }) => {
  const [showAllBookings, setShowAllBookings] = useState(false);

  const {
    isPending,
    data,
    error: erorr
  } = noddiAsync.useGet({
    type: URLKeys.getUpcomingBookingsForUserGroup,
    input: { userGroupId, pageSize: showAllBookings ? 1000 : DEFAULT_NUMBER_OF_BOOKINGS_SHOWN, page: 1 }
  });

  if (erorr) {
    return <ErrorPageWithTranslations apiError={erorr} />;
  }

  if (isPending) {
    return <NoddiCircularLoader />;
  }

  return (
    <BookingsSection
      bookingsPaginated={data}
      isUpcomingBooking
      setShowAllBookings={setShowAllBookings}
      showAllBookings={showAllBookings}
    />
  );
};

export default UpcomingBookings;
