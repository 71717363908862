import { t, Trans } from '@lingui/macro';
import { noddiAsync, URLKeys } from 'noddi-async';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { useAuthContext } from 'noddi-provider';
import { FixedBottomBarContainer, NoddiButton } from 'noddi-ui';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HelpScoutChat from '../../../../../../components/BookingFlow/HelpScoutChat';
import { ErrorPageWithTranslations } from '../../../../../../components/ErrorPageWithTrans';
import { useBookingContext } from '../../../../../../contexts/BookingContext';
import useActivateCoupon from '../../../../../../hooks/useActivateCoupon';
import { useMembershipPrograms } from '../../../../../BookingFlow/hooks/useMembershipPrograms';
import PriceSummary from '../../../../../BookingFlow/Steps/Summary/PriceSummary';
import ExpandBookingScreen from '../../ExpandBookingScreen';
import useExpandBookingPaths from '../../hooks/useExpandBookingPaths';

export const Summary = () => {
  const { activateCouponForSalesItem, couponsFromApi: fetchedCoupons } = useActivateCoupon();
  const { data: membershipData } = useMembershipPrograms();

  const { currentUserGroupId: userGroupId } = useAuthContext();
  const { id: bookingId } = useParams();
  const navigate = useNavigate();
  const { salesItems, bookingInputData, resetBookingData } = useBookingContext();
  const { bookingDetailsPath } = useExpandBookingPaths();

  const {
    mutateAsync: addSalesItemsToBooking,
    isPending: isAddSalesItemsToBookingPending,
    error: addSalesItemsToBookingError
  } = noddiAsync.usePost({
    type: URLKeys.postAddSalesItemsToBooking,
    queryConfig: {
      onSuccess: async () => {
        invalidateQueryExactMatch({
          urlKey: URLKeys.getUserBooking,
          input: { userGroupId: userGroupId!, bookingId: bookingId! }
        });
        resetBookingData();
        navigate(bookingDetailsPath);
      }
    }
  });

  useEffect(() => {
    if (fetchedCoupons?.length !== 0) {
      salesItems.forEach((salesItem) => {
        activateCouponForSalesItem({ salesItemId: salesItem.id, licensePlateNumber: salesItem.licensePlateNumber });
      });
    }
  }, [fetchedCoupons]);

  const handleConfirmExpandBooking = async () => {
    const cars = bookingInputData.selectedCars.map((car) => {
      const salesItemIds = salesItems
        .filter((item) => item.licensePlateNumber === car.licensePlateNumber)
        .map((item) => item.id);

      return {
        licensePlateNumber: car.licensePlateNumber,
        countryCode: car.licensePlateNumberCountryCode!,
        salesItemIds
      };
    });

    const payload = {
      bookingId: parseInt(bookingId!),
      cars
    };

    await addSalesItemsToBooking(payload);
  };

  if (addSalesItemsToBookingError) {
    return <ErrorPageWithTranslations apiError={addSalesItemsToBookingError} />;
  }

  return (
    <ExpandBookingScreen
      title={t`Does this look correct?`}
      description={t`This will be added to your initial bill`}
      CustomBottomBar={
        <FixedBottomBarContainer>
          <HelpScoutChat />
          <NoddiButton
            className='ml-auto'
            variant='success'
            loading={isAddSalesItemsToBookingPending}
            onClick={handleConfirmExpandBooking}
          >
            <Trans>Confirm</Trans>
          </NoddiButton>
        </FixedBottomBarContainer>
      }
    >
      <PriceSummary membershipData={membershipData} />
    </ExpandBookingScreen>
  );
};
