import { t } from '@lingui/macro';
import { NoddiChip, NoddiIcon } from 'noddi-ui';
import EditComment from './EditComment';

type Props = {
  slug?: string;
  isPaid?: boolean;
  isCancelled?: boolean;
  address?: string;
  dataText: string;
  canEditComment: boolean;
  commentsUser: string;
  bookingId: string;
};

export function MetaSection({
  isCancelled,
  isPaid,
  slug,
  address,
  dataText,
  canEditComment,
  commentsUser,
  bookingId
}: Props) {
  return (
    <div className='flex flex-col gap-2'>
      {isCancelled && (
        <div>
          <NoddiChip label={t`Canceled`} variant='destructive' />
        </div>
      )}
      {isPaid && (
        <div>
          <NoddiChip label={t`Paid`} variant='success' />
        </div>
      )}
      {address && (
        <div className='flex gap-2'>
          <NoddiIcon name='LocationPin' size='medium' />
          <p>{address}</p>
        </div>
      )}

      <div className='flex gap-2'>
        <NoddiIcon name='Calendar' size='medium' />
        <p>{dataText}</p>
      </div>
      {slug && (
        <div className='flex gap-2'>
          <NoddiIcon size='medium' name='Magnifier' />
          <p>{t`Booking reference : ${slug}`}</p>
        </div>
      )}
      {canEditComment && <EditComment commentsUser={commentsUser} bookingId={bookingId} />}
    </div>
  );
}
