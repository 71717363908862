import { useParams } from 'react-router-dom';
import routes from '../../../../../appRoutes';

const useExpandBookingPaths = () => {
  const { id: bookingId } = useParams();
  const bookingDetailsPath = routes.myBookingDetails.getPath({ id: bookingId! });
  const carPath = routes.expandBookingCar.getPath({ id: bookingId! });
  const salesItemsPath = routes.expandBookingSalesItem.getPath({ id: bookingId! });
  const addonsPath = routes.expandBookingAddon.getPath({ id: bookingId! });
  const tireHotelPath = routes.expandBookingTirehotel.getPath({ id: bookingId! });
  const summaryPath = routes.expandBookingSummary.getPath({ id: bookingId! });

  return { carPath, salesItemsPath, addonsPath, tireHotelPath, summaryPath, bookingDetailsPath };
};

export default useExpandBookingPaths;
