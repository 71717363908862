import { useLocation, useNavigate } from 'react-router-dom';

import {
  addonsStepPath,
  addressStepPath,
  carStepPath,
  servicesStepPath,
  timeSlotStepPath,
  wheelStorageStepPath
} from '../bookingFlowPaths';
import { navigateAndLog } from './navigateAndLog';
import { useStepCompletionValidation } from './useStepCompletionValidation';

export const useNavigateOnInvalidStep = () => {
  const navigate = useNavigate();

  const { pathname: activeStep } = useLocation();

  const { isAddressStepValid, isCarStepValid, isSalesItemStepValid, isWheelStorageStepValid, isTimeWindowsStepValid } =
    useStepCompletionValidation();

  /**
   * This function is used to validate the current step in a multi-step process.
   * It checks the active step and performs validation accordingly. If the validation fails, the function logs a validation message and navigates to the failed step.
   **/

  const navigateOnInvalidStep = () => {
    if (activeStep === addressStepPath) {
      return;
    }

    if (!isAddressStepValid) {
      navigateAndLog(activeStep, addressStepPath, navigate);
      return;
    }

    if (activeStep === carStepPath) {
      return;
    }

    if (!isCarStepValid) {
      navigateAndLog(activeStep, carStepPath, navigate);
      return;
    }

    if (activeStep === servicesStepPath) {
      return;
    }

    if (!isSalesItemStepValid) {
      navigateAndLog(activeStep, servicesStepPath, navigate);
      return;
    }

    if (activeStep === wheelStorageStepPath) {
      return;
    }

    // This is also safe for the case when no TH items are selected, as the function will return true when no TH items are selected
    if (!isWheelStorageStepValid) {
      navigateAndLog(activeStep, wheelStorageStepPath, navigate);
      return;
    }

    if (activeStep === timeSlotStepPath || activeStep === addonsStepPath) {
      return;
    }

    if (!isTimeWindowsStepValid) {
      navigateAndLog(activeStep, timeSlotStepPath, navigate);
    }
  };

  return { navigateOnInvalidStep };
};
