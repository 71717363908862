import { Trans, t } from '@lingui/macro';
import { NoddiBasicCard, NoddiIconButton, useNoddiToast } from 'noddi-ui';

const CopyReferralLink = ({ referralCode }: { referralCode: string }) => {
  const { noddiToast } = useNoddiToast();

  const referralLink = `${window.location.host}/referrals/${referralCode}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink);
    noddiToast.success(t`Referral link was copied`);
  };

  return (
    <NoddiBasicCard className='flex flex-col gap-4 bg-secondary-lightPurple text-secondary-black'>
      <p className='font-bold text-5'>
        <Trans>My referral link</Trans> 🎉
      </p>

      <div className='flex items-center gap-2'>
        <div className='w-full rounded-lg border border-secondary-black px-3 py-1.5'>
          <p className='max-w-50 truncate xxs:max-w-none'>{referralLink}</p>
        </div>
        <div>
          <NoddiIconButton iconName='Copy' variant='ghost' iconSize='medium' onClick={copyToClipboard} />
        </div>
      </div>
    </NoddiBasicCard>
  );
};

export default CopyReferralLink;
