import { Skeleton } from '@mui/material';
import { AvailableBookingTimeWindow, AvailableBookingTimeWindowsByDate } from 'noddi-async/src/types';
import { useAuthContext } from 'noddi-provider';
import { DateFormats, differenceBetweenDates, format, formatCurrencyAmount } from 'noddi-util';
import styled from 'styled-components';

import { cn } from '../../../helpers/utilts';
import { isTimeWindowUnavailable, parseTimeSlotFromPublicTimes } from './utils';

interface DateCardProps {
  date: string;
  isSelected: boolean;
  translations: {
    today: string;
    tomorrow: string;
  };
}

const DateCard = ({ date, isSelected, translations }: DateCardProps) => {
  const parsedDay = new Date(date);
  const parsedToday = new Date();
  const isToday = differenceBetweenDates(parsedDay, parsedToday, 'days') === 0;
  const isTomorrow = differenceBetweenDates(parsedDay, parsedToday, 'days') === 1;

  const getDayFormat = () => {
    if (isToday) {
      return translations.today;
    }
    if (isTomorrow) {
      return translations.tomorrow;
    }
    return format(parsedDay, DateFormats.SHORT_WEEKDAY_DAY_MONTH);
  };

  return (
    <div className='sticky top-0 z-50 flex h-14 w-full flex-col items-center justify-center gap-2 py-2 mdx:bg-primary-white'>
      <p className={cn('text-nowrap', isSelected && 'font-bold')}>{getDayFormat()}</p>
    </div>
  );
};

const StyledDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 100%;
  position: relative;
`;

export type OnSelectTimeWindowProps = {
  onAvailableSelect: (bookingTimeWindow: AvailableBookingTimeWindow) => void;
  onUnavailableSelect?: (bookingTimeWindow: AvailableBookingTimeWindow) => void;
};

type TimeWindowCardProps = {
  timeWindow?: AvailableBookingTimeWindow | null;
  isActive: boolean;
  isLoading: boolean;
  formattedSlot: string;
  translations: {
    soldOut: string;
  };
} & OnSelectTimeWindowProps;

const TimeWindowCard = ({
  formattedSlot,
  timeWindow,
  isLoading,
  onAvailableSelect,
  onUnavailableSelect,
  isActive,
  translations
}: TimeWindowCardProps) => {
  const { isSuperUser } = useAuthContext();

  if (timeWindow === undefined) {
    return null;
  }

  const isNull = timeWindow === null;
  const isUnavailable = timeWindow !== null && isTimeWindowUnavailable({ timeWindow });

  const handleOnClick = () => {
    if (!timeWindow) {
      return;
    }
    if (isUnavailable) {
      onUnavailableSelect?.(timeWindow);
    } else {
      onAvailableSelect(timeWindow);
    }
  };

  const getPriceText = () => {
    if (isUnavailable || isNull) {
      return translations.soldOut;
    }

    return formatCurrencyAmount(timeWindow.price, 0, timeWindow.currency);
  };

  if (isLoading) {
    return <Skeleton variant='rounded' height={44} width='100%' />;
  }

  return (
    <button
      onClick={handleOnClick}
      className={cn(
        'mx-auto flex h-16 w-[87px] flex-col items-center justify-center gap-1 self-stretch rounded-xl bg-secondary-lightPurple p-2',
        isUnavailable &&
          'pointer-events-none border border-secondary-silverGray bg-systemColors-transparent opacity-40',
        isActive && 'border-[3px] opacity-100 shadow-sm',
        isNull && 'invisible',
        isSuperUser && isUnavailable && 'pointer-events-auto'
      )}
    >
      <p className='text-center'>{formattedSlot}</p>
      <p className='text-center opacity-80'>{getPriceText()}</p>
    </button>
  );
};

type BookingTimeWindowPickerDateProps = {
  timeWindowsByDate: AvailableBookingTimeWindowsByDate;
  isLoading: boolean;
  selectedTimeWindowId: number | null | undefined;
  selectedTimeWindowDate?: string;
  uniqueTimeSlots: string[];
  translations: {
    today: string;
    tomorrow: string;
    soldOut: string;
  };
} & OnSelectTimeWindowProps;

const BookingTimeWindowPickerDate = ({
  timeWindowsByDate,
  selectedTimeWindowId,
  selectedTimeWindowDate,
  uniqueTimeSlots,
  translations,
  ...rest
}: BookingTimeWindowPickerDateProps) => {
  const findTimeWindow = (slot: string) => {
    return timeWindowsByDate.timeWindows?.find((timeWindow) => {
      if (!timeWindow?.startPublic || !timeWindow?.endPublic) {
        return false;
      }
      return parseTimeSlotFromPublicTimes(timeWindow.startPublic, timeWindow.endPublic) === slot;
    });
  };

  return (
    <StyledDateContainer>
      <DateCard
        date={timeWindowsByDate.date}
        isSelected={selectedTimeWindowDate === timeWindowsByDate.date}
        translations={translations}
      />
      <div className='relative bottom-3 h-0.25 w-50 bg-secondary-silverGray' />

      <div className='flex w-full flex-col items-center justify-center gap-7 sm:gap-12'>
        {uniqueTimeSlots.map((slot, idx) => {
          const timeWindow = findTimeWindow(slot);
          return (
            <div key={slot}>
              <div className='flex h-13 min-h-13 w-[87px] items-center'>
                <TimeWindowCard
                  translations={translations}
                  formattedSlot={slot}
                  key={`${timeWindow?.id}-${idx}`}
                  timeWindow={timeWindow}
                  isActive={selectedTimeWindowId !== undefined && timeWindow?.id === selectedTimeWindowId}
                  {...rest}
                />
              </div>
            </div>
          );
        })}
      </div>
    </StyledDateContainer>
  );
};

export default BookingTimeWindowPickerDate;
