import { Trans, t } from '@lingui/macro';
import { Form, Formik } from 'formik';
import { URLKeys, noddiAsync } from 'noddi-async';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { useAuthContext } from 'noddi-provider';
import { FieldWrapper, NoddiButton, NoddiFormTextInput } from 'noddi-ui';
import * as Yup from 'yup';

import { ApiErrorMessageWithTrans } from '../../../components/ApiErrorMessageWithTrans';

type Props = {
  slug: string;
};

const getValidationSchema = () => {
  return Yup.object().shape({
    authCode: Yup.string().required(t`Required`)
  });
};

export const ActivateMembershipCard = ({ slug }: Props) => {
  const { currentUserGroupId: userGroupId } = useAuthContext();

  const {
    mutateAsync: activateMembership,
    isPending: isActivateMembershipPending,
    error: activateMembershipError
  } = noddiAsync.usePost({
    type: URLKeys.postActivateMembership,
    queryConfig: {
      onSuccess: async () => {
        await invalidateQueryExactMatch({ urlKey: URLKeys.getMembershipFromSlug, input: { slug, userGroupId } });
      }
    }
  });

  if (!userGroupId) {
    return;
  }

  return (
    <div className='flex flex-col gap-2'>
      <p className='font-bold text-primary-darkPurple'>
        <Trans>Enter discount code to connect the membership benefits to your account</Trans> 🥳
      </p>
      <Formik
        initialValues={{
          authCode: ''
        }}
        validationSchema={getValidationSchema()}
        validateOnMount
        onSubmit={async (values) => {
          await activateMembership({ userGroupId, slug, ...values });
        }}
      >
        {({ isValid }) => (
          <Form>
            <FieldWrapper>
              <NoddiFormTextInput name='authCode' />

              <div className='flex justify-end'>
                <NoddiButton type='submit' disabled={!isValid} loading={isActivateMembershipPending}>
                  {t`Activate membership`}
                </NoddiButton>
              </div>
              {activateMembershipError && <ApiErrorMessageWithTrans error={activateMembershipError} isSmallVersion />}
            </FieldWrapper>
          </Form>
        )}
      </Formik>
    </div>
  );
};
