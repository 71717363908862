import { useEffect, useState } from 'react';
import { screens } from '../../tailwind-design-preset';

export const useIsWithinBreakpoint = (breakpoint: keyof typeof screens) => {
  const [isWithinBreakpoint, setIsWithinBreakpoint] = useState(false);

  useEffect(() => {
    const parsedBreakpointValue = parseInt(screens[breakpoint].replace('px', ''));
    const mediaQuery = window.matchMedia(`(max-width: ${parsedBreakpointValue}px)`);

    const updateBreakpointMatch = () => setIsWithinBreakpoint(mediaQuery.matches);

    updateBreakpointMatch(); // Set initial value
    mediaQuery.addEventListener('change', updateBreakpointMatch);

    return () => mediaQuery.removeEventListener('change', updateBreakpointMatch);
  }, [breakpoint]);

  return isWithinBreakpoint;
};
