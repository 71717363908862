import { Typography } from '@mui/material';
import { Stack } from '@mui/system';

import { colors } from '../../../../tailwind-design-preset';
import { NoddiIcon } from '../../../atoms/NoddiIcon';
import { resolveWheelSetStatusLogo, wheelSetStatusToTranslation } from './helpers';
import { TireType, Tires, WheelSetStatusType } from './types';

interface WheelHeaderProps {
  type?: TireType;
  wheelSetStatus: WheelSetStatusType;
  translations: {
    summerTires: string;
    winterTires: string;
    missingMeasurements: string;
    soonWornOut: string;
    wornOut: string;
    inAGoodCondition: string;
  };
}

const WheelHeader = ({ type, wheelSetStatus, translations }: WheelHeaderProps) => {
  return (
    <div className='flex gap-4'>
      <NoddiIcon name='WheelAngle' color={colors.primary.black} size='extraLarge' />
      <Stack direction='column'>
        <Typography>{type === Tires.summer ? translations.summerTires : translations.winterTires}</Typography>
        {wheelSetStatus && (
          <Stack direction='row' gap={1} alignItems='center'>
            {resolveWheelSetStatusLogo(wheelSetStatus)}
            <Typography variant='body2' color='text.secondary'>
              {wheelSetStatusToTranslation(wheelSetStatus, translations)}
            </Typography>
          </Stack>
        )}
      </Stack>
    </div>
  );
};

export { WheelHeader };
